import * as yup from 'yup';
import { PATTERNS } from '../constants/patterns';
import { isPostalCode, isPostalCodeExist } from './postal-code';

const calcAcademicPeriodDate = (y: number, m: number, d: number) => {
  let year = +y;
  const month = +m;
  const day = +d;

  if (month < 4 || (month === 4 && day === 1)) {
    year -= 1;
  }

  // YYYYMMDD
  return (year * 10000) + 400 + 1;
};

export const memberSchema = {
  'member[family_name]': {
    validation: yup
      .string()
      .trim()
      .required('名字を入力してください')
      .max(50, '名字は50文字以内で入力してください'),
    count: true,
  },

  'member[first_name]': {
    validation: yup
      .string()
      .trim()
      .required('名前を入力してください')
      .max(50, '名前は50文字以内で入力してください'),
    count: true,
  },

  'member[family_kana]': {
    validation: yup
      .string()
      .required('名字（ひらがな）を入力してください')
      .max(50, '名字（ふりがな）は50文字以内で入力してください')
      .matches(PATTERNS.HIRAGANA, '名字（ふりがな）はひらがなで入力してください'),
    count: true,
  },

  'member[first_kana]': {
    validation: yup
      .string()
      .required('名前（ひらがな）を入力してください')
      .max(50, '名前（ふりがな）は50文字以内で入力してください')
      .matches(PATTERNS.HIRAGANA, '名前（ふりがな）はひらがなで入力してください'),
    count: true,
  },

  'member[birthday(1i)]': {
    validation: yup
      .string()
      .required('生年月日を選択してください'),
    count: true,
  },

  'member[birthday(2i)]': {
    validation: yup
      .string()
      .required('生年月日を選択してください'),
    count: true,
  },

  'member[birthday(3i)]': {
    getValue: ($form: JQuery<HTMLSelectElement>) => {
      const $birthdays = $form.find('.js-form-manager__field--birthday');
      return {
        birthday: [...$birthdays].map(field => field.value),
        minAge: +($birthdays.last().data('form-manager-min-age')),
      };
    },
    getDestination: ($form: JQuery<HTMLElement>) => $form.find('.js-form-manager__field--birthday'),
    validation: yup
      .object()
      .test('is-fulfilled-birthday', '生年月日を選択してください', (value) => {
        const { birthday } = value;
        const y = birthday[0];
        const m = birthday[1];
        const d = birthday[2];

        if (!y || !m || !d) {
          return false;
        }
        return true;
      })
      .test('is-valid-birthday', '応募条件を満たす年齢に達していません', (value) => {
        const { birthday, minAge } = value;
        if (!minAge) {
          return true;
        }
        const y = birthday[0];
        const m = birthday[1];
        const d = birthday[2];
        const birthPeriod = calcAcademicPeriodDate(y, m, d);
        const now = new Date();
        const jobPeriod = calcAcademicPeriodDate((now.getFullYear() + 1) - (minAge + 1), now.getMonth() + 1, now.getDate());

        return birthPeriod <= jobPeriod;
      }),
    count: true,
  },

  'member[postal_code]': {
    validation: yup
      .string()
      .notRequired()
      .test('is-invalid-postal-code', '正しい郵便番号を入力してください', isPostalCode)
      .test('is-postal-code-exist', '郵便番号が見つかりません', isPostalCodeExist),
  },

  'member[prefecture_id]': {
    validation: yup
      .string()
      .required('都道府県を選択してください'),
    count: true,
  },

  'member[city_id]': {
    validation: yup
      .string()
      .required('市区町村を選択してください'),
    count: true,
  },

  'member[town]': {
    validation: yup
      .string()
      .max(100, '町名・番地は100文字以内で入力してください'),
  },

  'member[building]': {
    validation: yup
      .string()
      .max(100, '建物名は100文字以内で入力してください'),
  },

  'member[tel]': {
    validation: yup
      .string()
      .required('電話番号を入力してください')
      .matches(PATTERNS.TEL, '正しい電話番号を入力してください'),
    count: true,
  },

  'member[gender]': {
    getValue: ($form: JQuery<HTMLElement>) => {
      const value = $form.find('[name="member[gender]"]:checked').val();

      if (value === undefined) {
        return 0;
      }

      return +value || 0;
    },
    getStyleTargetElement: ($form: JQuery<HTMLElement>) => {
      return $form.find('[name="member[gender]"]').siblings('label');
    },
    validation: yup
      .number()
      .min(1, '性別を選択してください'),
    count: true,
  },

  'member[employment_status]': {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      const value = $form.find('[name="member[employment_status]"]:checked').val();

      if (value === undefined) {
        return 0;
      }

      return +value || 0;
    },
    getStyleTargetElement: ($form: JQuery<HTMLElement>) => {
      return $form.find('[name="member[employment_status]"]').siblings('label');
    },
    validation: yup
      .number()
      .min(1, '就業状況を選択してください'),
    count: true,
  },
};
