const isNotDefined = (value) => {
  return (value === null || value === undefined);
};

const isEqualArray = (target, compared) => {
  return (
    target.length === compared.length
    &&
    target.every((val, index) => {
      return val === compared[index];
    })
  );
};

const isBlank = (value) => {
  return value === undefined || value === null || value === '';
};

const isPresent = (value) => {
  return !isBlank(value);
};

const isBlankAll = (array) => {
  return array.every(isBlank);
};

const isPresentAll = (array) => {
  return array.every(isPresent);
};

export {
  isNotDefined,
  isEqualArray,
  isBlank,
  isPresent,
  isBlankAll,
  isPresentAll,
};
