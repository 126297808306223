import $ from 'jquery';
import { Modal } from './modal';

class SelectAttachDocumentMessageModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
    });
    this.$selectBox = null;
    this.$selectButton = null;
  }

  init = () => {
    this.modal.init();
    this.$selectBox = this.modal.$wrapper.find('.js-attach-document-message-select-box');
    this.$selectButton = this.modal.$wrapper.find('.js-attach-document-message-button');
    this.$selectButton.on('click', (e) => {
      e.preventDefault();
      window.location.href = `/members/messages/${ this.$selectBox.val() }/?select_document=1`;
    });
  }
}

export {
  SelectAttachDocumentMessageModal,
};
