import $ from 'jquery';
import { emitter } from './util/event-emitter';

class Tab {
  constructor(el, { navActiveClassName, contentActiveClassName }) {
    this.$wrapper = $(el);
    this.$nav = this.$wrapper.find('.js-tab__nav');
    this.$navItems = this.$nav.find('.js-tab__nav-item');
    this.$contentList = this.$wrapper.find('.js-tab__content-list');
    this.$contentItems = this.$contentList.find('.js-tab__content-item');
    this.contentActiveClassName = contentActiveClassName;
    this.navActiveClassName = navActiveClassName;
  }

  init = () => {
    this.$nav.on('click', '.js-tab__nav-link', (e) => {
      e.preventDefault();
      this.changeTab(e.target);
    });
  }

  changeTab = (target) => {
    const currentIndex = this.$contentList.find(`.${ this.contentActiveClassName }`).index();
    const $target = $(target);
    const index = $target.closest('.js-tab__nav-item').index();

    this.$navItems.eq(currentIndex).find('.js-tab__nav-link').removeClass(this.navActiveClassName);
    $target.addClass(this.navActiveClassName);
    this.$contentItems.eq(currentIndex).removeClass(this.contentActiveClassName);
    this.$contentItems.eq(index).addClass(this.contentActiveClassName);
  }
}

export {
  Tab,
};
