import * as yup from 'yup';

export const jobOfferSalarySchema = {
  jobOfferSalary: {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      return $form.find('.js-form-manager__field--job-offer-salary').filter(':checked').length;
    },
    getDestination: ($form: JQuery<HTMLInputElement>) => $form.find('.js-form-manager__field--job-offer-salary'),
    validation: yup
      .number()
      .min(1, '勤務形態を選択してください'),
    count: true,
  },
};
