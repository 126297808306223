import { schema } from './schema';

type SchemaKey = keyof typeof schema;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionSchema = () => ({ [key: string]: any });
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Schema = { [key: string]: any } | FunctionSchema;

export class SchemaResolver {
  schema: Schema;

  constructor({ schemaName }: { schemaName: SchemaKey }) {
    this.schema = schema[schemaName];
  }

  resolve = async (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema?: any;
  }> => {
    if (typeof this.schema === 'function') {
      const currentSchema = this.schema();

      if (currentSchema instanceof Promise) {
        return Promise.resolve(currentSchema);
      }

      return currentSchema;
    }
    return this.schema;
  }
}
