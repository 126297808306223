import $ from 'jquery';
import { Modal } from './modal';

class CareerRemoveConfirmModal {
  constructor({
    modalId,
    onHide,
    onShow,
  }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      onHide,
      onShow,
    });
  }

  init = () => {
    this.modal.init();
  }
}

export {
  CareerRemoveConfirmModal,
};
