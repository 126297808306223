import $ from 'jquery';
import { Modal } from './modal';
import { NotificationModal } from './notification-modal';

class DeleteSearchConditionModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      beforeShow: this.beforeShow,
    });

    this.conditionId = 0;
    this.$trigger = null;
    this.$deleteButton = $('.js-search-condition-deletion');
    this.deleteNotificationModal = new NotificationModal({ modalId: 'search-condition-deletion-complete', cookieId: 'search-condition-deletion-complete' });
  }

  beforeShow = ({ trigger }) => {
    this.$trigger = $(trigger);
    this.conditionId = this.$trigger.data('condition-id');
  }

  init = () => {
    this.modal.init();

    this.$deleteButton.on('click', this.deleteSearchCondition);
    this.deleteNotificationModal.init();
  }

  deleteSearchCondition = async () => {
    try {
      await $.ajax({
        type: 'DELETE',
        url: `/api/users/member_search_conditions/${ this.conditionId }`,
      });
    } catch (error) {
      if (this.hasResponseMessage(error)) {
        /* eslint-disable no-alert */
        alert(error.responseJSON.message);
      } else {
        alert('削除できませんでした');
        /* eslint-enable no-alert */
      }
      throw new Error(error);
    }
    window.location.reload();
  }

  hasResponseMessage = (response) => {
    return response && response.responseJSON && response.responseJSON.message;
  }
}

export {
  DeleteSearchConditionModal,
};
