import * as $ from 'jquery';
import Cookie from 'js-cookie';

class MemberNotification {
  /**
   * @param {string} el
   */
  constructor(el) {
    this.$wrapper = $(el);
    this.$closeButton = this.$wrapper.find('.js-member-notification__button--close');
    /** @type {'member_profile_hide' | 'member_celebration_hide'} */
    this.key = this.$wrapper.data('notification-key');
  }

  init = () => {
    this.$closeButton.on('click', (evt) => {
      evt.preventDefault();
      Cookie.set(this.key, true, { expires: 7 });
      this.$wrapper.remove();
    });
  }
}

export {
  MemberNotification,
};
