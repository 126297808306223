import $ from 'jquery';
import { Modal } from './modal';

class NoDocumentsModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
    });
    this.$createDocumentsButton = null;
  }

  init = () => {
    this.modal.init();
    this.$createDocumentsButton = this.modal.$wrapper.find('.js-create-documents');
    this.$createDocumentsButton.on('click', () => {
      this.modal.hide({});
    });
  }

  show = () => {
    this.modal.show({});
  }
}

export {
  NoDocumentsModal,
};
