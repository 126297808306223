import $ from 'jquery';
import { Modal } from './modal';

class ShigoTalkEntryModal {
  modalId: string | undefined;
  modal: Modal | undefined;

  constructor({ modalId }: { modalId: string }) {
    const el = $(`.js-modal[data-modal-id="${ modalId }"]`);
    if (el.length) {
      this.modalId = modalId;
      this.modal = new Modal({
        el,
      });
    }
  }

  init = () => {
    if (this.modal) {
      this.modal.init();
      if (this.modal.$wrapper.length > 0) {
        // shigo talk経由での応募でJMにlandingしてから即座にmodalが表示されるとユーザがどこにいるのか把握できなくなる可能性が高いと思われるため、
        // モーダルの表示は1000ms遅らせることでそのリスクを軽減する
        setTimeout(() => {
          if (this.modal) {
            this.modal.show({});
          }
        }, 1000);
      }
    }
  }
}

export {
  ShigoTalkEntryModal,
};
