import $ from 'jquery';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from 'body-scroll-lock';

const userAgent = navigator.userAgent.toLowerCase();
const isSafariOnIOS = userAgent.indexOf('iphone') > -1 && userAgent.indexOf('safari') > -1;

class Modal {
  constructor({
    el,
    isContentHeightDynamic = undefined,
    onHide = undefined,
    onShow = undefined,
    beforeShow = undefined,
  }) {
    this.$wrapper = $(el);
    this.$body = this.$wrapper.find('.js-modal__body');
    this.id = this.$wrapper.data('modal-id');
    this.openerSelector = `.js-modal-opener[data-modal-id="${ this.id }"]`;
    this.$closer = this.$wrapper.find('.js-modal__closer');
    this.hiddenClassName = this.getHiddenClassName();
    this.overlayClassName = this.getOverlayClassName();
    this.$overlay = $(`.${ this.overlayClassName }`);
    this.overlayHiddenClassName = 'c-overlay--invisible';
    this.$frame = this.$wrapper.find('.js-modal__frame');
    this.onShow = onShow;
    this.beforeShow = beforeShow;
    this.onHide = onHide;
    this.isContentHeightDynamic = isContentHeightDynamic;
  }

  cachedScrollTop = 0

  getHiddenClassName = () => {
    return this.$wrapper.data('modal-hidden-class-name') || 'c-modal--close';
  }

  getOverlayClassName = () => {
    return this.$wrapper.data('modal-target-overlay-class-name') || 'js-overlay';
  }

  init = () => {
    $(document.body).on('click', this.openerSelector, (e) => {
      e.preventDefault();
      this.show({
        trigger: e.currentTarget,
        onShow: this.onShow,
        beforeShow: this.beforeShow,
      });
    });
    this.$closer.on('click', (e) => {
      e.preventDefault();
      this.hide({
        onHide: this.onHide,
      });
    });
    this.$frame.on('click', (e) => {
      if (e.target === this.$frame[0]) {
        this.hide({
          onHide: this.onHide,
        });
      }
    });

    return this;
  }

  isDisabledOnLargeViewport = (trigger) => {
    return (window.innerWidth > 480) && $(trigger).data('modal-disabled-on-desktop');
  }

  lockBodyScroll = () => {
    // scroll lockしてもiphone safari では、アコーディオンでスクロール可能領域が表示された後に動的にスクロール可能なheightを持つケースではscroll lockできないことがある
    // ↑のケースでも強制的にscroll lockを効かせるために、iphoneではスクロール可能なheightを持つようにする
    if (isSafariOnIOS && this.isContentHeightDynamic) {
      const height = this.$body[0].clientHeight;
      const paddingBottom = Number(this.$body.css('padding-bottom').split('px')[0]);

      this.$body.children().eq(0).css({
        minHeight: (height - paddingBottom) + 1,
      });
    }

    this.$body.each((_, body) => {
      disableBodyScroll(body, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => {
          return el.getAttribute('data-body-scroll-lock-ignored');
        },
      });
    });
  }

  saveScrollTopPosition = () => {
    this.cachedScrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  }

  restoreScrollTopPosition = () => {
    window.scrollTo(0, this.cachedScrollTop);
    this.cachedScrollTop = 0;
  }

  show = async ({
    beforeShow = undefined,
    onShow = undefined,
    trigger = undefined,
  }) => {
    if (trigger && this.isDisabledOnLargeViewport(trigger)) {
      return;
    }

    if (beforeShow) {
      await beforeShow({
        trigger,
      });
    }

    this.saveScrollTopPosition();

    this.$wrapper.removeClass(this.hiddenClassName);
    this.$overlay.removeClass(this.overlayHiddenClassName);

    this.lockBodyScroll();

    if (onShow) {
      await onShow({
        trigger,
      });
    }
  }

  hide = ({
    onHide = null,
  } = {}) => {
    this.$wrapper.addClass(this.hiddenClassName);
    this.$overlay.addClass(this.overlayHiddenClassName);

    if (this.$body.length > 0) {
      this.$body[0].scrollTop = 0;
    }
    clearAllBodyScrollLocks();

    // iOS safari のバーチャルキーボードによる強制スクロール等でスクロール位置が意図せず変わってしまうケースを考慮して、モーダルを閉じる際にモーダル表示する直前のscroll位置を復元する
    this.restoreScrollTopPosition();

    if (onHide) {
      onHide();
    }
  }
}

export {
  Modal,
};
