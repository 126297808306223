import $ from 'jquery';
import * as yup from 'yup';
import { PATTERNS } from './constants/patterns';
import { emitter } from './util/event-emitter';
import { FootBar } from './foot-bar';
import { PreventMisapplicationModal } from './modules/modal';

const MESSAGE_TYPE_APPLICATION = '2';
const MESSAGE_TYPE_NORMAL = '7';
const MESSAGE_TYPE_REFUSE_SCOUT = '8';
const MESSAGE_TYPE_REPLY_ARRANGEMENT_INTERVIEW = '16';

const AGENT_AVAILABLE = '1';
const INTERVIEW_METHOD_OFFLINE_INTERVIEW = '1';
const FILE_SIZE_LIMIT = 10485760;
const FILE_TYPE = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];

class MessageForm {
  constructor({
    form,
    refusedScoutNotificationModal,
    noDocumentsModal,
    attachDocumentsModal,
  }) {
    this.$form = $(form);
    this.refusedScoutNotificationModal = refusedScoutNotificationModal;
    this.noDocumentsModal = noDocumentsModal;
    this.attachDocumentsModal = attachDocumentsModal;
    this.preventMisapplicationModal = new PreventMisapplicationModal({ modalId: 'prevent-misapplication', $form: this.$form });
    this.preventFalseScoutRefusalModal = new PreventMisapplicationModal({ modalId: 'prevent-false-scout-refusal', $form: this.$form });
    this.footBar = new FootBar('#js-message-foot-bar', { lowerLandmark: '.js-message-foot-bar-lower-landmark' });
    this.memberHasAnyDocuments = this.$form.data('has-any-documents');

    this.$attachedResume = this.$form.find('.js-attached-resume');
    this.$attachedResumeDeleteButton = this.$attachedResume.find('.js-attached-resume-delete');
    this.$attachedCareersheet = this.$form.find('.js-attached-careersheet');
    this.$attachedCareersheetDeleteButton = this.$attachedCareersheet.find('.js-attached-careersheet-delete');
    this.$formMemberResume = $('#js-member-resume-id');
    this.$formMemberCareersheet = $('#js-member-careersheet-id');
    this.$agentAvailable = this.$form.find('.js-select-agent-available');
    this.$replyTypesSelectBox = this.$form.find('.js-select-reply-type');
    this.$templateLink_1 = this.$form.find('.js-use-template_1');
    this.$templateLink_2 = this.$form.find('.js-use-template_2');
    this.$messageFormBody = this.$form.find('.js-message-form-body');
    this.$submitButton = this.$form.find('.js-form__submitter');
    this.$attachDocumentsTrigger = this.$form.find('.js-attach-documents-trigger');
    this.$attachFilesTrigger_1 = this.$form.find('.js-attach-files-trigger_1');
    this.$attachFilesTrigger_2 = this.$form.find('.js-attach-files-trigger_2');
    this.$attachFilesTrigger_3 = this.$form.find('.js-attach-files-trigger_3');
    this.$attachFilesTrigger_4 = this.$form.find('.js-attach-files-trigger_4');
    this.$attachedFile_1 = this.$form.find('.js-attached-file_1');
    this.$attachedFile_2 = this.$form.find('.js-attached-file_2');
    this.$attachedFile_3 = this.$form.find('.js-attached-file_3');
    this.$attachedFileDeleteButton_1 = this.$attachedFile_1.find('.js-attached-file-delete_1');
    this.$attachedFileDeleteButton_2 = this.$attachedFile_2.find('.js-attached-file-delete_2');
    this.$attachedFileDeleteButton_3 = this.$attachedFile_3.find('.js-attached-file-delete_3');

    this.$confirmSentence = this.$form.find('.js-confirm-sentence');

    this.$interviewLocaion = this.$form.find('.js-interview-location-group');
    this.$replyTypesLinkArea = this.$form.find('.js-reply-types-link-area');
    this.$termsArea = this.$form.find('.js-terms-area');
  }

  init = (formManagers) => {
    // NOTE: init後のformManagerのインスタンスが必要のため、init関数でもらうようにしている.
    this.formManager = formManagers[0];
    this.refusedScoutNotificationModal.init();
    this.preventMisapplicationModal.init();
    this.preventFalseScoutRefusalModal.init();
    this.noDocumentsModal.init();
    // 対象modalのformManagersのインスタンスを渡す
    this.attachDocumentsModal.init(formManagers[1]);
    this.footBar.init();

    this.$attachedResumeDeleteButton.on('click', this.deleteAttachedResume);
    this.$attachedCareersheetDeleteButton.on('click', this.deleteAttachedCareersheet);
    this.$attachedFileDeleteButton_1.on('click', this.deleteAttachedFile_1);
    this.$attachedFileDeleteButton_2.on('click', this.deleteAttachedFile_2);
    this.$attachedFileDeleteButton_3.on('click', this.deleteAttachedFile_3);
    this.$templateLink_1.on('click', (e) => {
      e.preventDefault();
      this.updateMessageFormBody('template_1');
    });
    this.$templateLink_2.on('click', (e) => {
      e.preventDefault();
      this.updateMessageFormBody('template_2');
    });
    this.$replyTypesSelectBox.on('change', this.changeMessageType);
    this.$agentAvailable.on('change', this.updateSubmitButtonStatus);
    this.$attachDocumentsTrigger.on('click', (e) => {
      e.preventDefault();
      if (this.memberHasAnyDocuments) {
        this.attachDocumentsModal.show({
          resumeId: this.$formMemberResume.val(),
          careersheetId: this.$formMemberCareersheet.val(),
        });
      } else {
        this.noDocumentsModal.show();
      }
    });

    this.$attachFilesTrigger_1.on('change', (e) => {
      e.preventDefault();
      const file = this.$attachFilesTrigger_1.find('.js-attach-files_1').prop('files')[0];
      if (file.size > FILE_SIZE_LIMIT) {
        this.$attachFilesTrigger_1.find('.js-attach-files_1').val('');
        this.$form.find('.js-max-size-attach-files').removeClass('u-hidden');
        return;
      }
      if (!FILE_TYPE.includes(file.type)) {
        this.$attachFilesTrigger_1.find('.js-attach-files_1').val('');
        this.$form.find('.js-attach-files-type').removeClass('u-hidden');
        return;
      }
      this.$attachedFile_1.removeClass('u-hidden');
      this.$attachedFile_1.find('.js-attached-file-name_1').text(file.name);
      this.$attachFilesTrigger_1.addClass('u-hidden');
      this.showAttachFilesTrigger();
      this.attachFilePreview(this.$attachFilesTrigger_1, file);
    });

    this.$attachFilesTrigger_2.on('change', (e) => {
      e.preventDefault();
      const file = this.$attachFilesTrigger_2.find('.js-attach-files_2').prop('files')[0];
      if (file.size > FILE_SIZE_LIMIT) {
        this.$attachFilesTrigger_2.find('.js-attach-files_2').val('');
        this.$form.find('.js-max-size-attach-files').removeClass('u-hidden');
        return;
      }
      if (!FILE_TYPE.includes(file.type)) {
        this.$attachFilesTrigger_2.find('.js-attach-files_2').val('');
        this.$form.find('.js-attach-files-type').removeClass('u-hidden');
        return;
      }
      this.$attachedFile_2.removeClass('u-hidden');
      this.$attachedFile_2.find('.js-attached-file-name_2').text(file.name);
      this.$attachFilesTrigger_2.addClass('u-hidden');
      this.showAttachFilesTrigger();
      this.attachFilePreview(this.$attachFilesTrigger_2, file);
    });

    this.$attachFilesTrigger_3.on('change', (e) => {
      e.preventDefault();
      const file = this.$attachFilesTrigger_3.find('.js-attach-files_3').prop('files')[0];
      if (file.size > FILE_SIZE_LIMIT) {
        this.$attachFilesTrigger_3.find('.js-attach-files_3').val('');
        this.$form.find('.js-max-size-attach-files').removeClass('u-hidden');
        return;
      }
      if (!FILE_TYPE.includes(file.type)) {
        this.$attachFilesTrigger_3.find('.js-attach-files_3').val('');
        this.$form.find('.js-attach-files-type').removeClass('u-hidden');
        return;
      }
      this.$attachedFile_3.removeClass('u-hidden');
      this.$attachedFile_3.find('.js-attached-file-name_3').text(file.name);
      this.$attachFilesTrigger_3.addClass('u-hidden');
      this.showAttachFilesTrigger();
      this.attachFilePreview(this.$attachFilesTrigger_3, file);
    });

    this.$attachFilesTrigger_4.on('click', () => {
      this.$form.find('.js-max-attach-files').removeClass('u-hidden');
    });

    $('input[name="message[interview_method]"]:radio').on('change', this.switchInterviewMethod);
    // NOTE: 初期表示に、対面面接がチェックされている時に、 `対面面接の面接場所` を表示させるため
    const value = $('input[name="message[interview_method]"]:checked').val();
    if (value === INTERVIEW_METHOD_OFFLINE_INTERVIEW) {
      this.$interviewLocaion.removeClass('u-hidden');
    }

    $('.js-change-message-type-normal').on('click', { messageType: MESSAGE_TYPE_NORMAL }, this.changeMessageType);
    $('.js-change-message-type-reply').on('click', { messageType: MESSAGE_TYPE_REPLY_ARRANGEMENT_INTERVIEW }, this.changeMessageType);
    emitter.on('formManager:submit', ({ $form }) => {
      if ($form.is(this.$form)) {
        const matchedKeyword = this.matchMisapplicationKeywords();
        if (this.isApplication() && matchedKeyword) {
          $('.js-prevent-misapplication-keyword').text(matchedKeyword[0]);
          this.preventMisapplicationModal.show();
        } else if (this.isRefuseScout() && this.matchApplicationTemplate()) {
          this.preventFalseScoutRefusalModal.show();
        } else {
          this.$submitButton.prop('disabled', true);
          this.$form.off('submit').submit();
        }
      }
    });
    emitter.on('createDocumentModal:create', () => {
      this.memberHasAnyDocuments = true;
    });
    emitter.on('documentCreatedModal:attachDocuments', this.attachDocuments);
    emitter.on('attachDocumentsModal:attachDocuments', this.attachDocuments);

    // NOTE: this.$replyTypesSelectBox の要素を取得できている時だけ処理を実行する
    if (this.$replyTypesSelectBox.length > 0) {
      // NOTE: プリセットされたメッセージタイプごとに初期表示処理を実行する
      this.processByMessageType(this.$replyTypesSelectBox.val());
      this.buildValidationByMessageType();
      this.formManager.setBeforeValidation(this.buildValidationByMessageType);
    }
  }

  addSchemaReplyArrangementInterview = () => {
    const preferredDatetimeElements = $('.js-reply_preferred-datetime__target')
      .find('input[name="message[reply_preferred_datetime][]"]');
    if (preferredDatetimeElements.length > 0) {
      this.formManager.addSchema('message[reply_preferred_datetime][]', {
        getValue: ($form) => {
          return $form.find('.js-form-manager__field--reply_preferred_datetime:checked').length;
        },
        validation: yup
          .string()
          .test('required', '面接希望日を選択してください', (checkedLength) => {
            return checkedLength > 0;
          }),
      });
    }

    const inteviewMethodElements = $('.js-reply-interview-method__target')
      .find('input[name="message[interview_method]"]');
    if (inteviewMethodElements.length > 0) {
      this.formManager.addSchema('message[interview_method]', {
        getValue: ($form) => {
          const value = $form.find('[name="message[interview_method]"]:checked').val();
          if (value === undefined) {
            return 0;
          }
          return +value || 0;
        },
        validation: yup
          .string()
          .test('required', '面接方法を選択してください', (checkedLength) => {
            return checkedLength > 0;
          }),
      });
    }
  }

  buildValidationByMessageType = () => {
    const messageType = this.$replyTypesSelectBox.val();

    // TODO: 将来的にタイプ別のバリデーションが増えた時、
    //       タイプごとのスキーマビルド処理をリファクタしたほうが良い。
    switch (messageType) {
      case MESSAGE_TYPE_NORMAL:
        this.formManager.removeSchema('message[reply_preferred_datetime][]');
        this.formManager.removeSchema('message[interview_method]');
        $('.js-form-manager__fieldset--preferred-datetime').find('.js-form-manager__error--hook').removeClass('js-form-manager__error-msg');
        $('.js-form-manager__fieldset--interview-method').find('.js-form-manager__error--hook').removeClass('js-form-manager__error-msg');
        break;
      case MESSAGE_TYPE_REPLY_ARRANGEMENT_INTERVIEW:
        this.addSchemaReplyArrangementInterview();
        $('.js-form-manager__fieldset--preferred-datetime').find('.js-form-manager__error--hook').addClass('js-form-manager__error-msg');
        $('.js-form-manager__fieldset--interview-method').find('.js-form-manager__error--hook').addClass('js-form-manager__error-msg');
        break;
      default:
        break;
    }
  }

  switchInterviewMethod = (e) => {
    const value = e.currentTarget.value;
    if (value === INTERVIEW_METHOD_OFFLINE_INTERVIEW) {
      this.$interviewLocaion.removeClass('u-hidden');
    } else {
      this.$interviewLocaion.addClass('u-hidden');
    }
  }

  // NOTE: メッセージタイプの切り替え時に、caseでタイプが被った場合は、
  //       case文の中でさらに分岐して判断するようにして拡張する
  changeMessageType = (e) => {
    let value = e.currentTarget.value;

    // NOTE: データがバインドされている場合は、プルダウンのonChange以外の
    //       イベントを経由しているので、以下の値を使用する
    if (e.data) {
      value = e.data.messageType;
      this.$replyTypesSelectBox.val(value);
    }
    this.processByMessageType(value);
  }

  processByMessageType = (value) => {
    switch (value) {
      case MESSAGE_TYPE_NORMAL:
        this.$replyTypesLinkArea.addClass('u-hidden');
        this.$termsArea.addClass('u-hidden');
        this.$submitButton.prop('disabled', false);
        break;
      case MESSAGE_TYPE_REPLY_ARRANGEMENT_INTERVIEW:
        this.$replyTypesLinkArea.removeClass('u-hidden');
        break;
      case MESSAGE_TYPE_APPLICATION:
        this.updateSubmitButtonStatus();
        this.changeTemplateLink();
        this.$termsArea.removeClass('u-hidden');
        break;
      case MESSAGE_TYPE_REFUSE_SCOUT:
        this.$submitButton.prop('disabled', false);
        this.changeTemplateLink();
        this.$termsArea.addClass('u-hidden');
        break;
      default:
        break;
    }
  }

  attachDocuments = ({ resume, careersheet }) => {
    if (resume || careersheet) {
      this.$confirmSentence.removeClass('u-hidden');
    }
    if (resume) {
      this.$attachedResume.removeClass('u-hidden');
      this.$attachedResume.find('.js-attached-resume-name').text(resume.name);
      this.$formMemberResume.val(resume.id);
    }
    if (careersheet) {
      this.$attachedCareersheet.removeClass('u-hidden');
      this.$attachedCareersheet.find('.js-attached-careersheet-name').text(careersheet.name);
      this.$formMemberCareersheet.val(careersheet.id);
    }
  }

  deleteAttachedResume = () => {
    this.$formMemberResume.val('');
    this.$attachedResume.addClass('u-hidden');
    this.deleteConfirmSentenceIfNeed();
  }

  deleteAttachedCareersheet = () => {
    this.$formMemberCareersheet.val('');
    this.$attachedCareersheet.addClass('u-hidden');
    this.deleteConfirmSentenceIfNeed();
  }

  deleteAttachedFile_1 = () => {
    this.$attachedFile_1.addClass('u-hidden');
    this.$attachFilesTrigger_1.find('.js-attach-files_1').val('');
    this.showAttachFilesTrigger();
  }

  deleteAttachedFile_2 = () => {
    this.$attachedFile_2.addClass('u-hidden');
    this.$attachFilesTrigger_2.find('.js-attach-files_2').val('');
    this.showAttachFilesTrigger();
  }

  deleteAttachedFile_3 = () => {
    this.$attachedFile_3.addClass('u-hidden');
    this.$attachFilesTrigger_3.find('.js-attach-files_3').val('');
    this.showAttachFilesTrigger();
  }

  showAttachFilesTrigger = () => {
    const file1 = this.$attachFilesTrigger_1.find('.js-attach-files_1').prop('files')[0] != null;
    const file2 = this.$attachFilesTrigger_2.find('.js-attach-files_2').prop('files')[0] != null;
    const file3 = this.$attachFilesTrigger_3.find('.js-attach-files_3').prop('files')[0] != null;

    switch (false) {
      case file1:
        this.$attachFilesTrigger_1.removeClass('u-hidden');
        this.$attachFilesTrigger_2.addClass('u-hidden');
        this.$attachFilesTrigger_3.addClass('u-hidden');
        this.$attachFilesTrigger_4.addClass('u-hidden');
        this.$form.find('.js-max-size-attach-files').addClass('u-hidden');
        this.$form.find('.js-attach-files-type').addClass('u-hidden');
        this.$form.find('.js-max-attach-files').addClass('u-hidden');
        break;
      case file2:
        this.$attachFilesTrigger_2.removeClass('u-hidden');
        this.$attachFilesTrigger_1.addClass('u-hidden');
        this.$attachFilesTrigger_3.addClass('u-hidden');
        this.$attachFilesTrigger_4.addClass('u-hidden');
        this.$form.find('.js-max-size-attach-files').addClass('u-hidden');
        this.$form.find('.js-attach-files-type').addClass('u-hidden');
        this.$form.find('.js-max-attach-files').addClass('u-hidden');
        break;
      case file3:
        this.$attachFilesTrigger_3.removeClass('u-hidden');
        this.$attachFilesTrigger_1.addClass('u-hidden');
        this.$attachFilesTrigger_2.addClass('u-hidden');
        this.$attachFilesTrigger_4.addClass('u-hidden');
        this.$form.find('.js-max-size-attach-files').addClass('u-hidden');
        this.$form.find('.js-attach-files-type').addClass('u-hidden');
        this.$form.find('.js-max-attach-files').addClass('u-hidden');
        break;
      default:
        this.$form.find('.js-max-size-attach-files').addClass('u-hidden');
        this.$form.find('.js-attach-files-type').addClass('u-hidden');
        this.$attachFilesTrigger_4.removeClass('u-hidden');
    }
  }

  attachFilePreview = (attachFilesTrigger, file) => {
    const $targetAttachFileElement = attachFilesTrigger.find('.js-attach-file-preview');
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const imgTag = `<img src='${fileReader.result}'>`;
      $targetAttachFileElement.html(imgTag);
    };
  }

  deleteConfirmSentenceIfNeed = () => {
    if (this.$formMemberResume.val() === '' && this.$formMemberCareersheet.val() === '') {
      this.$confirmSentence.addClass('u-hidden');
    }
  }

  updateMessageFormBody = (templateName) => {
    const template = this.$replyTypesSelectBox.find('option:selected').data(templateName);
    if (template) {
      this.$messageFormBody.val(template);
    } else {
      this.$messageFormBody.val('');
    }
  }

  changeTemplateLink = () => {
    this.$replyTypesLinkArea.removeClass('u-hidden');
    this.syncTemplateLinkUI();
  }

  syncTemplateLinkUI = () => {
    const $selectedOption = this.$replyTypesSelectBox.find('option:selected');
    const template_1 = $selectedOption.data('template_1');
    const template_2 = $selectedOption.data('template_2');
    const explanation_1 = $selectedOption.data('explanation_1');
    const explanation_2 = $selectedOption.data('explanation_2');

    if (template_1) {
      this.$templateLink_1.removeClass('u-hidden');
      this.$templateLink_1.text(explanation_1);
    } else {
      this.$templateLink_1.addClass('u-hidden');
      this.$templateLink_1.text('');
    }
    if (template_2) {
      this.$templateLink_2.removeClass('u-hidden');
      this.$templateLink_2.text(explanation_2);
    } else {
      this.$templateLink_2.addClass('u-hidden');
      this.$templateLink_2.text('');
    }
  }

  matchMisapplicationKeywords = () => {
    return this.$messageFormBody.val().match(PATTERNS.MISAPPLICATION_KEYWORDS);
  }

  isApplication = () => {
    return this.$replyTypesSelectBox.find('option:selected').val() === MESSAGE_TYPE_APPLICATION;
  }

  matchApplicationTemplate = () => {
    return this.$messageFormBody.val() === this.$replyTypesSelectBox.find(`option[value= ${MESSAGE_TYPE_APPLICATION} ]`).data('template_1')
      || this.$messageFormBody.val() === this.$replyTypesSelectBox.find(`option[value= ${MESSAGE_TYPE_APPLICATION} ]`).data('template_2');
  }

  isRefuseScout = () => {
    return this.$replyTypesSelectBox.find('option:selected').val() === MESSAGE_TYPE_REFUSE_SCOUT;
  }

  updateSubmitButtonStatus = () => {
    const $agentAvailable = this.$form.find('[name="member[agent_available]"]:checked');

    if ((this.$replyTypesSelectBox.val() !== MESSAGE_TYPE_APPLICATION) || ($agentAvailable.length === 0)) {
      return;
    }

    if ($agentAvailable[0].value !== AGENT_AVAILABLE) {
      this.$submitButton.prop('disabled', true);
    } else {
      this.$submitButton.prop('disabled', false);
    }
  }

}

export {
  MessageForm,
};
