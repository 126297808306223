import $ from 'jquery';
import { getActualHeight } from './util/actual-height';
import { emitter } from './util/event-emitter';

type Callback = {
  beforeOpen: ({ targetBody }: { targetBody: HTMLElement }) => void;
  onOpen: ({ targetBody }: { targetBody: HTMLElement }) => void;
  onClose: () => void;
}

const noop = () => {
};

class Accordion {
  $wrapper: JQuery<HTMLElement>;

  toggleClassName: string;

  isSyncHeight?: string;

  isSyncOpenByHashLink?: string;

  beforeOpen: Callback['beforeOpen'];

  onOpen: Callback['onOpen'];

  onClose: Callback['onClose'];

  constructor(el: HTMLElement, { beforeOpen, onOpen, onClose }: Partial<Callback> = {}) {
    this.$wrapper = $(el);
    this.toggleClassName = this.$wrapper.data('accordion-toggle-class-name');
    this.isSyncHeight = this.$wrapper.data('accordion-sync-height');
    this.isSyncOpenByHashLink = this.$wrapper.data('accordion-sync-open-by-hash-link');

    this.beforeOpen = beforeOpen || noop;
    this.onOpen = onOpen || noop;
    this.onClose = onClose || noop;
  }

  onProgressOpening = false

  init = () => {
    this.$wrapper.on('click', '.js-accordion__item-trigger', (e) => {
      e.preventDefault();
      this.toggle(e.currentTarget);
    });
    if (this.isSyncHeight) {
      emitter.on('accordion:syncHeight', ({ triggers }: { triggers: HTMLElement[] }) => {
        triggers.forEach((el) => {
          this.syncHeight(el);
        });
      });
    }
    if (this.isSyncOpenByHashLink) {
      if (window.location.hash) {
        this.syncOpenByHashLink(); // init 時点で既に hash link がある場合に発火
      }
      $(window).on('hashchange', this.syncOpenByHashLink);
    }
    emitter.on('accordion:close', ({ targets }: { targets: HTMLElement[] }) => {
      targets.forEach((target) => {
        this.close(target);
      });
    });
  }

  getTargetEls = (target: HTMLElement) => {
    const $targetTrigger = $(target);
    const $targetAccordion = $targetTrigger.closest('.js-accordion__item');
    const $targetBody = $targetAccordion.find('.js-accordion__item-body');

    return {
      $targetTrigger,
      $targetAccordion,
      $targetBody,
    };
  }

  toggle = async (target: HTMLElement) => {
    if (this.onProgressOpening) {
      return;
    }
    const {
      $targetTrigger,
    } = this.getTargetEls(target);

    if ($targetTrigger.hasClass(this.toggleClassName)) {
      this.close(target);
    } else {
      this.open(target);
    }
  }

  close = (target: HTMLElement) => {
    const {
      $targetTrigger,
      $targetBody,
    } = this.getTargetEls(target);
    const targetBodyEl = $targetBody[0];

    // height: auto; の場合transition効かないので、事前にpxで値を入れておく
    if (!targetBodyEl.style.height) {
      targetBodyEl.style.height = $targetBody.css('height');
    }

    setTimeout(() => {
      $targetTrigger.removeClass(this.toggleClassName);
      targetBodyEl.style.height = '0';
      targetBodyEl.style.overflow = '';
      this.onClose();
    }, 0);
  }

  closeAll = () => {
    const $triggers = this.$wrapper.find('.js-accordion__item-trigger');

    $triggers.each((_, trigger) => {
      return this.close(trigger);
    });
  }

  open = async (target: HTMLElement) => {
    this.onProgressOpening = true;

    const {
      $targetTrigger,
      $targetBody,
    } = this.getTargetEls(target);

    await this.beforeOpen({ targetBody: $targetBody[0] });

    const actualHeight = getActualHeight($targetBody[0]);

    $targetTrigger.addClass(this.toggleClassName);
    $targetBody[0].style.height = actualHeight;
    $targetBody.one('transitionend', () => {
      $targetBody[0].style.overflow = 'visible';
      this.onProgressOpening = false;
    });
    this.onOpen({ targetBody: $targetBody[0] });
  }

  syncHeight = async (target: HTMLElement) => {
    const {
      $targetTrigger,
      $targetBody,
    } = this.getTargetEls(target);

    // open している accordion のみ再計算
    if (!$targetTrigger.hasClass(this.toggleClassName)) {
      return;
    }

    $targetBody[0].style.height = '';
    const actualHeight = getActualHeight($targetBody[0]);
    $targetBody[0].style.height = actualHeight;
  }

  syncOpenByHashLink = async () => {
    const hash = window.location.hash.replace('#', ''); // 返り値ブレ対策
    const $openTarget = this.$wrapper.find(`.js-accordion__item-trigger[id="${hash}"]`).first();

    if (!$openTarget.length) {
      return;
    }

    await this.open($openTarget[0]);
    await this.syncHeight($openTarget[0]);
    // hash link 遷移時に js-accordion__item-trigger にフォーカスがあたり
    // ブラウザによって要素の枠が強調表示されるのを抑止する
    $openTarget.blur();
  }
}

export {
  Accordion,
};
