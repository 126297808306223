import $ from 'jquery';
import { Modal } from './modal';
import { NotificationModal } from './notification-modal';

class RenameSearchConditionModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      beforeShow: this.beforeShow,
    });
    this.conditionId = 0;
    this.conditionName = null;
    this.$trigger = null;
    this.$conditionNameInput = this.modal.$wrapper.find('input');
    this.$conditionNameSaveButton = this.modal.$wrapper.find('.js-search-condition-rename');
    this.renameNotificationModal = new NotificationModal({ modalId: 'search-condition-rename-complete', cookieId: 'search-condition-rename-complete' });
  }

  beforeShow = ({ trigger }) => {
    this.$trigger = $(trigger);
    this.conditionId = this.$trigger.data('condition-id');
    this.conditionName = this.$trigger.data('condition-name');
    this.$conditionNameInput.val(this.conditionName);
  }

  init = () => {
    this.modal.init();
    this.$conditionNameInput.on('keyup', this.checkConditionNameInput);
    this.$conditionNameSaveButton.on('click', this.renameSearchCondition);
    this.renameNotificationModal.init();
  }

  renameSearchCondition = async () => {
    try {
      await $.ajax({
        type: 'POST',
        url: `/api/users/member_search_conditions/${ this.conditionId }`,
        data: {
          name: this.conditionName,
        },
      });
    } catch (error) {
      if (this.hasResponseMessage(error)) {
        /* eslint-disable no-alert */
        alert(error.responseJSON.message);
      } else {
        alert('保存できませんでした');
        /* eslint-enable no-alert */
      }
      throw new Error(error);
    }
    window.location.reload();
  }

  checkConditionNameInput = () => {
    const inputVal = this.$conditionNameInput.val();

    if (inputVal === '' || inputVal.length > 100) {
      // invalid inputNmae
      this.$conditionNameSaveButton.addClass('c-button--disabled');
    } else {
      // valid inputName
      this.$conditionNameSaveButton.removeClass('c-button--disabled');
      this.conditionName = inputVal;
    }
  }

  hasResponseMessage = (response) => {
    return response && response.responseJSON && response.responseJSON.message;
  }
}

export {
  RenameSearchConditionModal,
};
