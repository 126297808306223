import $ from 'jquery';
import { Modal } from './modal';
import { emitter } from '../../util/event-emitter';

class SimpleModal {
  modalId: string;

  modal: Modal;

  constructor({ modalId, onShow }: { modalId: string; onShow?: () => void }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      onShow,
      onHide: (): void => {
        emitter.emit('tab:init');
      },
    });
  }

  init = (): void => {
    this.modal.init();
  }
}

export {
  SimpleModal,
};
