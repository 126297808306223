import $ from 'jquery';

const $form = $('.js-footer-contact-form');
const $textArea = $('.js-footer-contact-textarea');
let isLoading = false;

export const footerContactManager = () => {
  if ($form.length === 0) return;

  $form.on('submit', (event) => {
    event.preventDefault();
    if (isLoading) return;

    isLoading = true;

    if (!$textArea.val()) {
      isLoading = false;
      return;
    }

    $.ajax({
      method: 'POST',
      url: $form.attr('action'),
      data: $form.serialize(),
    })
    .then((response) => {
      $form.html(response.html);
    })
    .always(() => {
      isLoading = false;
    });
  });
};
