import * as yup from 'yup';
import $ from 'jquery';

export const memberQualificationsAcquisitionScheduledSchema = {
  'member[qualifications_acquisition_scheduled]': {
    validation: yup
      .string()
      .test('is-duplicated_qualification', '保有資格・免許と取得予定の資格・免許は違う資格を選択してください。', () => {
        const errorMessage = $('.js-duplicated-qualification-by-job-category__error-msg').text();
        if (errorMessage.length > 0) {
          return false;
        }
        return true;
      }),
  },
};
