import $ from 'jquery';
import { isIOS } from './util/device';

// SelectBoxのoption省略を防ぐためのTips
class IOSDrumrollOmissionPreventer {
  constructor(selectBox) {
    this.$selectBox = $(selectBox);
  }

  init = () => {
    if (isIOS()) {
      this.appendOptgroup();
    }
  }

  appendOptgroup = () => {
    this.$selectBox.append($('<optgroup>'));
  }
}

export {
  IOSDrumrollOmissionPreventer,
};
