import $ from 'jquery';
import { getActualHeight } from './util/actual-height';
import { isLargeScreen } from './util/screen-size';

class Collapse {
  constructor(el) {
    this.$wrapper = $(el);
    this.$trigger = this.$wrapper.find('.js-collapse__trigger');
    this.$target = this.$wrapper.find('.js-collapse__target');
    this.toggleClassName = this.$trigger.data('collapse-opened');
    this.isMobileOnly = this.$wrapper.data('collapse-mobile-only');
    this.height = 0;
    this.onTransition = false;
  }

  init = () => {
    if (this.isMobileOnly && isLargeScreen()) {
      return;
    }
    this.height = getActualHeight(this.$target[0]);
    this.$wrapper.on('click', this.toggle);
  }

  toggle = (e) => {
    e.preventDefault();

    if (this.onTransition) {
      return;
    }

    this.onTransition = true;

    if (this.$trigger.hasClass(this.toggleClassName)) {
      this.hide();
    } else {
      this.show();
    }
  }

  hide = () => {
    this.$trigger.removeClass(this.toggleClassName);
    this.$trigger.one('transitionend', () => {
      this.$target[0].style.height = 0;
      this.onTransition = false;
    });
  }

  show = () => {
    this.$trigger.addClass(this.toggleClassName);
    this.$target[0].style.height = this.height;
    this.$trigger.one('transitionend', () => {
      this.onTransition = false;
    });
  }
}

export { Collapse };
