import $ from 'jquery';

const HIDDEN_CLASS_NAME = 'u-hidden';

class Loader {
  static $wrapper = $('#js-loader-wrapper')

  static $loader = Loader.$wrapper.find('.js-loader')
  static $inlineLoader = Loader.$wrapper.find('.js-loader-inline');

  /**
   * FIXME: HTML 構造上 inline-loader 表示されるため表示とともに隠している
   * 本来なら HTML 構造の方 or inline-loader の方を見直すべき
   */
  static show = () => {
    Loader.$inlineLoader.addClass(HIDDEN_CLASS_NAME);
    Loader.$wrapper.removeClass(HIDDEN_CLASS_NAME);
  }

  static hide = () => {
    Loader.$inlineLoader.removeClass(HIDDEN_CLASS_NAME);
    Loader.$wrapper.addClass(HIDDEN_CLASS_NAME);
  }
}

const INNER_CENTERED_CLASS_NAME = 'c-loader--inner-centered';

class InlineLoader extends Loader {
  static $loader = Loader.$wrapper.find('.js-loader-inline')

  static insert = ($destination, { isInnerCentered } = { isInnerCentered: false }) => {
    const $clone = InlineLoader.$loader.clone();

    if (isInnerCentered) {
      $clone.addClass(INNER_CENTERED_CLASS_NAME);
    }

    $destination.append($clone).addClass('js-loader-is-inserted');
  }

  static remove = () => {
    const $destination = $('.js-loader-is-inserted');
    const $loader = $destination.find('.js-loader-inline');

    $loader.remove();
  }

  static isAlreadyExist = () => {
    const $destination = $('.js-loader-is-inserted');

    return $destination.find('.js-loader-inline').length > 0;
  }
}

const CONTENT_LOADER_CLASS_NAME = 'c-content-loader';

class ContentLoader {
  static show = ($contentWrapper, src) => {
    if ($contentWrapper.find('.js-content-loader').length) {
      return;
    }

    const icon = `<img src="${src}" class="js-content-loader ${CONTENT_LOADER_CLASS_NAME}" />`;
    $contentWrapper.append(icon);
  }

  static hide = ($contentWrapper) => {
    $contentWrapper.find('.js-content-loader').remove();
  }
}

export {
  Loader,
  InlineLoader,
  ContentLoader,
};
