import $ from 'jquery';
import Cookie from 'js-cookie';
import { Modal } from './modal';

class LeaveAlertModal {
  constructor(modalId) {
    this.NEVER_SHOW_AGAIN_COOKIE_ID = `leave-alert-modal-never-show-again__${modalId}`;
    this.$el = $(`.js-modal[data-modal-id="${modalId}"]`);
    this.modal = new Modal({
      el: this.$el,
    });
  }

  init = () => {
    this.hasNeverShowAgainCookie = JSON.parse(Cookie.get(this.NEVER_SHOW_AGAIN_COOKIE_ID) || false);
    if (this.hasNeverShowAgainCookie) {
      return;
    }

    this.alreadyShown = false;
    this.modal.$wrapper.find('.js-leave-alert-modal-never-show-again').on('change', this.toggleNeverShowAgain);

    switch (this.modal.id) {
      case 'recommend-registration-modal':
      case 'confirm-leave-alert':
        // onpopstate イベントは cancel 不可につき、ダミー履歴を挿入し「戻る」を 1 回分無効化する
        history.pushState(null, null, null);
        $(window).on('popstate', this.showRecommendRegistrationModal);
        $(document).on('click', '.js-recommend-registration-modal-prevent-link--title-logo', this.showRecommendRegistrationModal);
        break;
      default:
        break;
    }

    this.modal.init();
  }

  showRecommendRegistrationModal = (e) => {
    if (this.alreadyShown) {
      return;
    }
    this.alreadyShown = true;

    if (e.type === 'click') {
      // ロゴリンク押下時、遷移をキャンセルしつつ無効化した「戻る」を 1 回実行することで
      // モーダルを閉じたあとに「戻る」が正常に動作するようにしてからモーダルを出現させる
      e.preventDefault();
      e.stopPropagation();
      history.back();

      // ロゴリンク押下時
      $('.js-recommend-registration-modal-back-btn').addClass('u-hidden');
      $('.js-recommend-registration-modal-to-top-btn').removeClass('u-hidden');
    }

    this.modal.show({});
  }

  toggleNeverShowAgain = (e) => {
    // 有効期限はキープの「非表示にする」と同じ 1 week
    Cookie.set(this.NEVER_SHOW_AGAIN_COOKIE_ID, e.target.checked, { expires: 7, path: '/' });
  }
}

export {
  LeaveAlertModal,
};
