import $ from 'jquery';
import { Modal } from './modal';
import { emitter } from '../../util/event-emitter';

class RenameDocumentModal {
  constructor({ modalId }) {
    this.modalId = modalId; // rename-resume or rename-careersheet
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      beforeShow: this.beforeShow,
    });

    this.documentId = null;
    this.documentName = null;
    this.$textField = null;
    this.$submitButton = null;
    this.$documentListItemName = null;
    this.changedNotificationModal = new Modal({ el: $('.js-modal[data-modal-id="changed-notification"]') });
  }

  beforeShow = ({ trigger }) => {
    this.$trigger = $(trigger);
    this.documentId = this.$trigger.data('document-id');
    this.$documentListItemName = this.$trigger.closest('.js-document-list__item').find('.js-document-name');
    this.documentName = this.$documentListItemName.text();
    this.$textField.val(this.documentName);
  }

  init = () => {
    this.modal.init();
    this.$submitButton = this.modal.$wrapper.find('.js-modal__submitter');
    this.$textField = this.modal.$wrapper.find('.js-document-textfield');
    this.$submitButton.on('click', (e) => {
      e.preventDefault();
      this.handleSubmit();
    });
    this.$textField.on('keyup', this.checkTextField);
    this.changedNotificationModal.init();
  }

  checkTextField = () => {
    if (this.$textField.val() === '') {
      this.$submitButton.addClass('c-button--disabled');
    } else {
      this.$submitButton.removeClass('c-button--disabled');
      this.documentName = this.$textField.val();
    }
  }

  saveDocument = async () => {
    try {
      await $.ajax({
        type: 'POST',
        url: this.getAPIUrl(),
        data: {
          name: this.documentName,
        },
      });
    } catch (error) {
      /* eslint-disable no-alert */
      alert('保存できませんでした');
      /* eslint-enable no-alert */
      throw new Error(error);
    }
    this.updateDocumentName();
    this.modal.hide({});
    this.changedNotificationModal.show({});
  }

  getAPIUrl = () => {
    if (this.modalId === 'rename-resume') {
      return `/api/users/resumes/change_name/${ this.documentId }`;
    }
    return `/api/users/careersheets/change_name/${ this.documentId }`;
  }

  updateDocumentName = () => {
    const $documentItemAnchor = this.$documentListItemName.find('.c-text-link');

    if (this.modalId === 'rename-resume' && $documentItemAnchor.length > 0) {
      $documentItemAnchor.text(this.$textField.val());
      return;
    }

    this.$documentListItemName.text(this.documentName);
  }

  handleSubmit = () => {
    if (this.modalId === 'rename-resume') {
      emitter.emit('formManager:validateForm', {
        callback: () => {
          this.saveDocument();
        },
      });
    } else {
      this.saveDocument();
    }
  };
}

export {
  RenameDocumentModal,
};
