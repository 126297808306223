import $ from 'jquery';
import Cookie from 'js-cookie';

class PreferredDisplayCampaign {
  init = () => {
    // safariの https://stackoverflow.com/questions/14795944/jquery-click-events-not-working-in-ios/16006333#16006333 を回避するために cursor: pointer;
    // 使いたいけど、カード自体にcursor: ponter;が付与されていると内包するボタン要素でのtap-highlight-colorが効かないので、document.bodyを使用せずに回避する
    $('.js-wrapper').on('click', '.js-preferred-display-campaign', this.handleClick);
  }

  /**
   *
   * @param {JQuery.ClickEvent<any, any, HTMLElement>} e
   */
  handleClick = (e) => {
    this.saveCookie(e.currentTarget);
    this.sendToGA(e.currentTarget);
  }

  /**
   * @memberof PreferredDisplayCampaign
   * @param {HTMLElement} target
   */
  saveCookie = (target) => {
    Cookie.set('campaign_url', $(target).data('campaign-url'), {
      expires: 1,
      path: '/',
    });
  }

  /**
   * @memberof PreferredDisplayCampaign
   * @param {HTMLElement} target
   */
  sendToGA = (target) => {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'preferredDisplayBanner',
      eventAction: 'click',
      eventLabel: $(target).data('preferred-display-campaign-id'),
    });
  }
}

export {
  PreferredDisplayCampaign,
};
