const BREAK_PONIT = 480;

const isSmallScreen = () => window.innerWidth <= BREAK_PONIT;

const isLargeScreen = () => window.innerWidth > BREAK_PONIT;

export {
  isSmallScreen,
  isLargeScreen,
};
