import $ from 'jquery';
import { InstantlyShowModal } from './modules/modal';
import { Loader } from './loader';

class RestartGuidanceManager {
  constructor() {
    // モーダルがあるかどうかを判断
    this.shouldProceed = $('.js-modal-for-leading-to-restart').length > 0;

    if (!this.shouldProceed) {
      return;
    }

    this.leadingModal = new InstantlyShowModal({
      modalId: 'leading-to-restart',
      beforeShow: this.configureLeadingModal,
    });
    this.restartedModal = new InstantlyShowModal({ modalId: 'job-hunting-restarted' });
  }

  init = () => {
    if (!this.shouldProceed) {
      return;
    }

    this.leadingModal.init({
      beforeShowArg: this.leadingModal.modal,
    });
  }

  configureLeadingModal = (arg) => {
    // 再開ボタンの動きを設定
    const modal = arg.trigger;
    modal.$wrapper.find('.js-job-hunting-restarted-modal-trigger').on('click', async (e) => {
      e.preventDefault();

      // Backdropがちらつかないように、念の為Loaderを表示してからモーダルを消す
      Loader.show();
      modal.hide();

      try {
        await $.ajax({
          type: 'POST',
          url: '/api/users/members/restart',
        });
        this.restartedModal.init({});
      } catch (error) {
        if (error.responseJSON && error.responseJSON.message) {
          alert(error.responseJSON.message);
        } else {
          alert('再開できませんでした');
        }
      } finally {
        Loader.hide();
      }
    });
  }
}

export {
  RestartGuidanceManager,
};
