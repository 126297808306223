import $ from 'jquery';
import { Modal } from './modal';
import { emitter } from '../../util/event-emitter';

class InstantlyShowModal {
  constructor({ modalId, beforeShow }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${this.modalId}"]`),
      onHide: () => {
        emitter.emit('tab:init');
      },
    });
    this.beforeShow = beforeShow;
  }

  init = ({ beforeShowArg }) => {
    this.modal.init();
    this.modal.show({
      beforeShow: this.beforeShow,
      trigger: beforeShowArg,
    });
  }
}

export {
  InstantlyShowModal,
};
