import $ from 'jquery';
import { Modal } from './modal';

class CarouselModal {
  constructor({ modalId, beforeShow }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      beforeShow,
    });
  }

  init = () => {
    this.modal.init();
  }
}

export {
  CarouselModal,
};
