import $ from 'jquery';
import { emitter } from './util/event-emitter';

class GridTab {
  constructor(el, { navActiveClassName }) {
    this.$wrapper = $(el);
    this.navActiveClassName = navActiveClassName;
  }

  init = () => {
    if (this.$wrapper.length < 1) {
      return;
    }
    this.$wrapper.on('click', '.js-grid-tab__nav-trigger', (e) => {
      e.preventDefault();
      this.changeTab(e.currentTarget);
    });
  }

  isDisabledOnSmallViewport = () => {
    return (window.innerWidth <= 480);
  }

  changeTab = (target) => {
    if (this.isDisabledOnSmallViewport()) {
      return;
    }

    const $target = $(target);
    const $targetNavItem = $target.closest('.js-grid-tab__nav-item');
    const $currentNavItem = this.$wrapper.find(`.${ this.navActiveClassName }`);

    $currentNavItem.removeClass(this.navActiveClassName);
    $targetNavItem.addClass(this.navActiveClassName);
    emitter.emit('gridTab:change', {
      $content: $targetNavItem.next(),
      $current: $currentNavItem.next(),
    });
  }
}

export {
  GridTab,
};
