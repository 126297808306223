import $ from 'jquery';
import { Modal } from './modal';
import { Loader } from '../../loader';
import { emitter } from '../../util/event-emitter';
import { isIOS } from '../../util/device';

class DocumentCreatedModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
    });

    this.createdResumeId = '';
    this.createdResumeName = null;
    this.createdCareersheetId = '';
    this.createdCareersheetName = null;
    this.$submitButton = this.modal.$wrapper.find('.js-modal__submitter');
    this.$createdResumeWrapper = this.modal.$wrapper.find('.js-created-resume');
    this.$createdCareersheetWrapper = this.modal.$wrapper.find('.js-created-careersheet');
  }

  init = () => {
    this.modal.init();
    this.$submitButton.on('click', (e) => {
      e.preventDefault();
      this.attachCreatedDocuments();
    });
  }

  attachCreatedDocuments = () => {
    if (this.createdResumeId !== '') {
      const resume = { id: this.createdResumeId, name: this.createdResumeName };
      emitter.emit('documentCreatedModal:attachDocuments', { resume });
    }
    if (this.createdCareersheetId !== '') {
      const careersheet = { id: this.createdCareersheetId, name: this.createdCareersheetName };
      emitter.emit('documentCreatedModal:attachDocuments', { careersheet });
    }
    this.reset();
    this.modal.hide({});
  }

  show = ({ createdResume, createdCareersheet }) => {
    if (createdResume) {
      this.createdResumeId = createdResume.id;
      this.createdResumeName = createdResume.name;
      this.$createdResumeWrapper.removeClass('u-hidden');
      this.$createdResumeWrapper.find('.js-created-resume__name').text(this.createdResumeName);
    }
    if (createdCareersheet) {
      this.createdCareersheetId = createdCareersheet.id;
      this.createdCareersheetName = createdCareersheet.name;
      this.$createdCareersheetWrapper.removeClass('u-hidden');
      this.$createdCareersheetWrapper.find('.js-created-careersheet__name').text(this.createdCareersheetName);
    }
    this.modal.show({});
  }

  reset = () => {
    this.createdResumeId = '';
    this.createdResumeName = null;
    this.createdCareersheetId = '';
    this.createdCareersheetName = null;
  }
}

class CreateDocumentModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      onHide: this.onHide,
    });
    this.documentCreatedModal = new DocumentCreatedModal({ modalId: 'document-created' });
    this.resumeName = '';
    this.careersheetName = '';
    this.$resumeTextField = this.modal.$wrapper.find('.js-resume-textfield');
    this.$careersheetTextField = this.modal.$wrapper.find('.js-careersheet-textfield');
    this.$footBar = this.modal.$wrapper.find('.js-modal__foot-bar');
    this.$submit = this.modal.$wrapper.find('.js-modal__submitter');
    this.$createButton = this.modal.$wrapper.find('.js-document-create');
    this.$form = this.modal.$wrapper.find('.js-create-document-form');
    this.submitDisabledClassName = this.$form.data('submit-disabled-class-name');
  }

  init = () => {
    this.modal.init();
    this.documentCreatedModal.init();

    // iOS ではキーボード出現時にposition: fixed;の固定フッターが適切な位置に配置されず入力の妨げになりうる挙動をするので、キーボード出現中は固定フッターを非表示にしておく
    if (isIOS()) {
      this.$form.on('focus', 'input', this.hideFootBar);
      this.$form.on('blur', 'input', this.showFootBar);
    }

    this.$form.on('keydown', 'input:text', this.preventTextFieldEnterSubmit);
    this.$form.on('keyup', 'input:text', this.checkTextField);
    this.$form.on('submit', (e) => {
      e.preventDefault();
      this.disableSubmit();
      this.$form.off('submit').submit();
    });
    this.$createButton.on('click', () => {
      this.create();
    });
    this.checkTextField();
  }

  onHide = () => {
    this.reset();
  }

  reset = () => {
    this.resumeName = '';
    this.careersheetName = '';
    this.$resumeTextField.val('');
    this.$careersheetTextField.val('');
  }

  showFootBar = () => {
    this.$footBar.removeClass('u-hidden');
  }

  hideFootBar = () => {
    this.$footBar.addClass('u-hidden');
  }

  preventTextFieldEnterSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  create = async () => {
    Loader.show();
    this.disableSubmit();
    let response = null;
    try {
      response = await $.ajax({
        type: 'POST',
        url: '/api/users/resumes/create/',
        data: {
          resume_name: this.resumeName,
          career_sheet_name: this.careersheetName,
        },
      });
    } catch (error) {
      if (this.hasResponseMessage(error)) {
        alert(error.responseJSON.message);
      }
      throw new Error(error);
    } finally {
      Loader.hide();
      this.activateSubmit();
    }
    const createdResume = response.resume;
    const createdCareersheet = response.career_sheet;
    this.modal.hide({});
    this.documentCreatedModal.show({ createdResume, createdCareersheet });
    emitter.emit('createDocumentModal:create', { createdResume, createdCareersheet });
  }

  checkTextField = () => {
    this.resumeName = this.$resumeTextField.val();
    this.careersheetName = this.$careersheetTextField.val();

    if (!this.resumeName && !this.careersheetName) {
      this.disableSubmit();
    } else {
      this.activateSubmit();
    }
  }

  disableSubmit = () => {
    this.$submit.addClass(this.submitDisabledClassName);
  }

  activateSubmit = () => {
    this.$submit.removeClass(this.submitDisabledClassName);
  }

  hasResponseMessage = (response) => {
    return response && response.responseJSON && response.responseJSON.message;
  }
}

export {
  CreateDocumentModal,
};
