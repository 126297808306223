import $ from 'jquery';
import { emitter } from './util/event-emitter';
import { isNotDefined } from './util/is';
import { Modal } from './modules/modal';
import { LeaveAlertModal } from './modules/modal/leave-alert-modal';
import { SchemaResolver } from './validations/SchemaResolver';

const THRESHOLD = 98;
const TEXT_FIELD_CLASSES = '.js-form-manager__field[type="text"], .js-form-manager__field[type="password"], .js-form-manager__field[type="email"], .js-form-manager__field[type="number"], textarea.js-form-manager__field';

class FormManager {
  constructor({ form, errorClass, completedClass, $counter, $total, callback }) {
    this.$form = $(form);
    this.$submit = this.$form.find('.js-form-manager__submit');
    this.errorClass = errorClass;
    this.completedClass = completedClass;

    const ignored = this.$form.data('form-manager-ignored');
    this.ignored = ignored ? ignored.split(',').map(v => v.trim()) : [];

    this.isAsyncSubmit = this.$form.data('form-manager-submit-async');
    this.$counter = $counter;
    this.$total = $total;

    this.hasLeaveAlert = !!this.$form.data('form-manager-leave-alert');
    this.hasAllwaysLeaveAlert = !!this.$form.data('form-manager-allways-leave-alert');

    // @see https://github.com/medley-inc/job-medley/issues/16226
    // @see https://github.com/medley-inc/job-medley/blob/develop/docs/REGISTRATION_APPLICATION_FORM_ON_USERS.md
    this.leaveAlertModalId = this.$form.data('form-manager-leave-alert-modal-id');
    this.leaveAlertModal = new LeaveAlertModal(this.leaveAlertModalId);
    this.hasLeaveAlertModal = !!this.leaveAlertModal.modal.$wrapper.length;
    // ↑ LeaveAlert と同時に発火しないよう無効化 ( beforeunload とモーダル操作を両立できないため
    if (this.hasLeaveAlertModal) {
      this.hasLeaveAlert = false;
      this.hasAllwaysLeaveAlert = false;
    }

    this.modal = new Modal({
      el: $('.js-modal[data-modal-id="registration-duplicated-mail-address"]'),
    });

    this.callback = {
      onFocus: () => {},
      onBlur: () => {},
      beforeSubmit: () => {},
      beforeValidation: () => {},
      onValidationMessageUpdated: () => {},
      afterUpdateCounter: () => {},
      ...callback,
    };
  }

  registerSchema = async () => {
    const parsedSchema = await new SchemaResolver({ schemaName: this.$form.data('form-manager-schema') }).resolve();

    this.schema = this.filterIgnoredFromSchema(parsedSchema);

    return this;
  }

  addSchema = (key, obj) => {
    this.schema[key] = obj;
  }

  removeSchema = (key) => {
    delete this.schema[key];
  }

  filterIgnoredFromSchema = (initialSchema) => {
    return Object.keys(initialSchema).reduce((accumulator, current) => {
      if (!this.ignored.includes(current)) {
        accumulator[current] = initialSchema[current];// eslint-disable-line
      }
      return accumulator;
    }, {});
  }

  init = async () => {
    await this.registerSchema();

    if (this.hasLeaveAlertModal) {
      this.leaveAlertModal.init();
    }
    if (this.hasAllwaysLeaveAlert) {
      this.enableLeaveAlert();
    }
    if (this.hasLeaveAlert) {
      this.$form.on('change', 'input, select, textarea', this.enableLeaveAlert);
    }
    this.updateCounter();
    emitter.on('formManager:validateFields', ({ fields }) => {
      fields.forEach(($field) => {
        this.handleFieldValidation($field[0]);
      });
    });
    emitter.on('formManager:validateForm', ({ callback }) => {
      this.validateForm({ onlyCount: false, eventType: 'click' }).then((res) => {
        this.updateValidationMessage(res);
        if (this.getErrors(res).length === 0) {
          callback();
        } else {
          this.scrollToError();
        }
      });
    });
    emitter.on('formManager:reRegisterSchema', this.registerSchema);
    this.$form.on('submit', (e) => {
      e.preventDefault();
      if (this.hasAllwaysLeaveAlert) {
        this.disableLeaveAlert();
      }
      if (this.hasLeaveAlert) {
        this.disableLeaveAlert();
      }

      this.callback.beforeValidation();
      this.validateForm({ onlyCount: false, eventType: 'submit' }).then((res) => {
        if (this.getErrors(res).length > 0) {
          if (this.showDuplicatedEmailModal(res)) {

            const memberEmail = $('#member_email').val();

            this.modal.init();
            this.modal.show({});

            const modalEmail = document.querySelector('.js-member-email');
            modalEmail.textContent = memberEmail;

            const url = [...document.querySelectorAll('.js-replace-query-parameter')];
            this.replaceQueryParameter(url, memberEmail);
          }
          this.updateValidationMessage(res);
          this.scrollToError();
          if (this.hasLeaveAlert) {
            this.enableLeaveAlert();
          }
        } else {
          this.callback.beforeSubmit();
          this.handleSubmit();
        }
      });
    });

    this.$form.on('focus', `${ TEXT_FIELD_CLASSES }, select`, this.callback.onFocus);
    this.$form.on('blur', `${ TEXT_FIELD_CLASSES }, select`, this.callback.onBlur);

    this.$form.on('change', TEXT_FIELD_CLASSES, (e) => {
      this.updateFieldToDirty(e.target);
    });
    this.$form.on('keyup change', '.js-form-manager__field', (e) => {

      if (this.isTextField(e.target) && !this.isDirtyField(e.target)) { // 初回の入力中はエラー表示しない
        return;
      }
      this.handleFieldValidation(e.target);

      const linkedName = $(e.target).data('form-manager-link-to');
      if (linkedName) {
        const $linkedField = $(`[name="${ linkedName }"]`);
        this.handleFieldValidation($linkedField[0]);
      }
    });
  }

  isTextField = (target) => {
    return $(target).filter(TEXT_FIELD_CLASSES).length > 0;
  }

  handleFieldValidation = (target) => {
    this.callback.beforeValidation();
    const key = this.getSchemaKey(target);
    const value = !isNotDefined(this.getSchemaValue(key)) ? this.getSchemaValue(key) : target.value;

    this.validateField(key, value).then((res) => {
      this.updateValidationMessage(res);
    });
  }

  confirmLeave = (e) => {
    e.preventDefault();
    e.returnValue = 'このページを離れようとしています。';
  }

  enableLeaveAlert = () => {
    window.addEventListener('beforeunload', this.confirmLeave);
  }

  disableLeaveAlert = () => {
    window.removeEventListener('beforeunload', this.confirmLeave);
  }

  updateFieldToDirty = (target) => {
    $(target).data('form-manager-dirty', 1);
  }

  isDirtyField = (target) => {
    if (target.tagName.toUpperCase() === 'SELECT') {
      return true;
    }
    if (target.type === 'radio') {
      return true;
    }
    if (target.type === 'checkbox') {
      return true;
    }

    return $(target).data('form-manager-dirty') === 1;
  }

  updateCounter = async () => {
    if (this.$counter.length >= 1) {
      const { total, completed } = await this.getCompleted();
      this.$counter.text(completed);
      this.$total.text(total);
    }
    this.callback.afterUpdateCounter();
  }

  updateSubmitStatus = (isDisabled) => {
    this.$submit.prop('disabled', isDisabled);
  }

  handleSubmit = () => {
    if (this.$submit.prop('disabled')) {
      return;
    }

    if (this.isAsyncSubmit) {
      emitter.emit('formManager:submit', {
        $form: this.$form,
      });
    } else {
      this.updateSubmitStatus(true);
      this.$form.off('submit').submit();
    }
  }

  getErrors = (validatedResultList) => {
    return [].concat(...(validatedResultList.map(validated => validated.errors)));
  }

  filterOnlyCountable = () => {
    return Object.keys(this.schema).reduce((accumulator, current) => {
      if (this.schema[current].count) {
        accumulator[`${ current }`] = this.schema[`${ current }`];// eslint-disable-line
      }
      return accumulator;
    }, {});
  }

  validateForm = ({ onlyCount = false, eventType }) => {
    const targetFields = onlyCount ? Object.keys(this.filterOnlyCountable()) : Object.keys(this.schema);
    const fields = targetFields.reduce((accumulator, current) => {
      accumulator[`${ current }`] = !isNotDefined(this.getSchemaValue(current)) ? this.getSchemaValue(current) : this.$form.find(`.js-form-manager__field[name="${ current }"]`).val();// eslint-disable-line
      return accumulator;
    }, {});
    const validatedList = Object.keys(fields).map((key) => {
      return this.validate(key, fields[key], eventType);
    });

    return new Promise((resolve) => {
      return Promise.all(validatedList).then((resultList) => {
        return resolve([
          ...resultList,
        ]);
      });
    });
  }

  getCompleted = async () => {
    const result = await this.validateForm({ onlyCount: true });
    const total = result.length;
    const errors = this.getErrors(result).length;

    return {
      completed: total - errors,
      total,
    };
  }

  validateField = (key, value) => {
    return new Promise((resolve) => {
      this.validate(key, value).then((result) => {
        const errors = this.getValidated(result, 'errors');

        return resolve([{
          key,
          errors,
        }]);
      });
    });
  }

  validate = (key, value, eventType) => {
    return new Promise((resolve, reject) => {
      if (!this.schema[`${ key }`]) {
        return reject();
      }

      return this.schema[`${ key }`].validation.validate(value, {
        context: {
          eventType,
        },
      })
      .then((val) => {
        return resolve({
          key,
          value: val,
          errors: [],
        });
      }).catch((err) => {
        return resolve({
          key,
          errors: err.errors,
        });
      });
    });
  }

  getValidated = (validated, key) => {
    return validated[key];
  }

  updateValidationMessage = (validatedResultList) => {
    let fields = [];
    const errorStyleTargets = [];

    validatedResultList.forEach((validatedResult) => {
      const $fields = this.getDestinationField(validatedResult.key);
      const $styleTarget = this.getStyleTargetElement(validatedResult.key);
      const $msg = $fields.closest('.js-form-manager__fieldset').find('.js-form-manager__error-msg');

      if (validatedResult.errors && validatedResult.errors.length) {
        errorStyleTargets.push($styleTarget);
        $msg.text(validatedResult.errors);
      } else {
        $styleTarget.removeClass(this.errorClass).addClass(this.completedClass);
        $msg.text('');
      }
      fields = [...fields, ...$fields.toArray()];
    });

    errorStyleTargets.forEach((errorStyleTarget) => {
      errorStyleTarget.removeClass(this.completedClass).addClass(this.errorClass);
    });

    this.updateCounter();
    this.callback.onValidationMessageUpdated({ fields });
  }

  scrollToError = () => {
    const firstError = this.$form.find('.js-form-manager__error-msg').not(':empty').closest('.js-form-manager__fieldset')[0];
    if (firstError) {
      const y = this.$form.find('.js-form-manager__error-msg').not(':empty').closest('.js-form-manager__fieldset')[0].offsetTop - THRESHOLD;
      window.scrollTo(0, y);
    }
  }

  getDestinationField = (key) => {
    return this.schema[key].getDestination ? this.schema[key].getDestination(this.$form) : this.$form.find(`.js-form-manager__field[name="${ key }"]`);
  }

  getStyleTargetElement = (key) => {
    return this.schema[key].getStyleTargetElement ? this.schema[key].getStyleTargetElement(this.$form) : this.$form.find(`.js-form-manager__field[name="${ key }"]`);
  }

  getSchemaKey = (target) => {
    const $target = $(target);

    return $target.data('form-manager-key') ? $target.data('form-manager-key') : target.name;
  }

  getSchemaValue = (key) => {
    return (this.schema[key] && this.schema[key].getValue) ? this.schema[key].getValue(this.$form) : null;
  }

  showDuplicatedEmailModal = (res) => {
    const emailIndex = this.getFieldIndex(res, 'member[email]');

    if ((emailIndex !== -1) && (res[emailIndex].errors.indexOf('登録済みのメールアドレスです') !== -1)) {
      if (document.querySelector('.js-modal[data-modal-id=registration-duplicated-mail-address]')) {
        return true;
      }
    }
    return false;
  }

  getFieldIndex = (res, field) => {
    return res.findIndex(({ key }) => key === field);
  }

  replaceQueryParameter = (empty_email_url, param) => {
    empty_email_url.forEach((el) => {
      const email_filled_url = el.getAttribute('href').replace('replaced_email', param);
      el.setAttribute('href', email_filled_url);
    });
  }

  setBeforeSubmit = (func) => {
    this.callback.beforeSubmit = func;
  }

  setBeforeValidation = (func) => {
    this.callback.beforeValidation = func;
  }

  setAfterUpdateCounter = (func) => {
    this.callback.afterUpdateCounter = func;
  }
}

export {
  FormManager,
};
