import $ from 'jquery';

class DateOptionsGenerator {
  constructor(el) {
    this.$wrapper = $(el);
    this.$year = this.$wrapper.find('.js-date-options-generator__year');
    this.$month = this.$wrapper.find('.js-date-options-generator__month');
    this.$date = this.$wrapper.find('.js-date-options-generator__date');
    this.defaultDateOption = null;
  }

  init = () => {
    this.defaultDateOption = this.$date.children().eq(0)[0].outerHTML;
    this.$year.on('change', this.generate);
    this.$month.on('change', this.generate);
  }

  generate = () => {
    const year = this.$year.val();
    const month = this.$month.val();
    const endDateOfMonth = new Date(year, month, 0).getDate();
    const dates = Array.from(new Array(endDateOfMonth), (_, i) => i + 1);
    const dateOptions = [0, ...dates].map((date) => {
      if (date === 0) {
        return this.defaultDateOption;
      }
      if (parseInt(this.$date.val(), 10) === date) {
        return `<option value="${ date }" selected>${ date }</option>`;
      }
      return `<option value="${ date }">${ date }</option>`;
    });

    this.$date.empty().append(dateOptions);
  }
}

export {
  DateOptionsGenerator,
};
