import $ from 'jquery';

class CheckedGroupSwitcher {
  static toggleInputs = (target) => {
    const $inputs = $(target).closest('.js-checked-group-switcher')
      .find('.js-checked-group-switcher__input')
      .not(':disabled');

    $inputs.prop('checked', target.checked);

    return $inputs;
  }

  static toggleController = (target) => {
    const $wrapper = $(target).closest('.js-checked-group-switcher');
    const $inputs = $wrapper.find('.js-checked-group-switcher__input');
    const $controller = $wrapper.find('.js-checked-group-switcher__controller');
    const $checkedInputs = $inputs.filter((_, el) => el.checked);
    const isdAllInputsChecked = $checkedInputs.length === $inputs.length;

    $controller.prop('checked', isdAllInputsChecked);

    return $controller;
  }
}

export {
  CheckedGroupSwitcher,
};
