import $ from 'jquery';

class Toaster {
  constructor(el) {
    this.$el = $(el);
  }

  hide = () => {
    setTimeout(() => {
      this.$el.addClass('c-notification-bar--hidden');
    }, 2000);
  }
}

export {
  Toaster,
};
