import $ from 'jquery';
import { Modal } from './modal';

class DeleteDocumentModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      beforeShow: this.beforeShow,
    });

    this.documentType = null;
    this.documentId = null;
    this.$submitButton = null;
    this.$documentList = null;
    this.$documentListItem = null;
    this.$documentListWrapper = null;
    this.deletedNotificationModal = new Modal({ el: $('.js-modal[data-modal-id="deleted-notification"]') });

  }

  beforeShow = ({ trigger }) => {
    this.$trigger = $(trigger);
    this.documentType = this.$trigger.data('document-type');
    this.documentId = this.$trigger.data('document-id');
    this.$documentListItem = this.$trigger.closest('.js-document-list__item');
    this.$documentList = this.$documentListItem.closest('.js-document-list');
    this.$documentListWrapper = this.$documentList.closest('.js-document-wrapper');
  }

  init = () => {
    this.modal.init();
    this.$submitButton = this.modal.$wrapper.find('.js-modal__submitter');
    this.$submitButton.on('click', (e) => {
      e.preventDefault();
      this.deleteDocument();
    });
    this.deletedNotificationModal.init();
  }

  deleteDocument = async () => {
    try {
      await $.ajax({
        type: 'DELETE',
        url: this.getAPIUrl(),
      });
    } catch (error) {
      /* eslint-disable no-alert */
      alert('削除できませんでした');
      /* eslint-enable no-alert */
      throw new Error(error);
    }
    this.$documentListItem.remove();
    this.syncUI();
    this.modal.hide({});
    this.deletedNotificationModal.show({});
  }

  getAPIUrl = () => {
    if (this.documentType === 'resume') {
      return `/api/users/resumes/${ this.documentId }`;
    }
    return `/api/users/careersheets/${ this.documentId }`;
  }

  syncUI = () => {
    if (this.$documentList.find('li').length === 0) {
      this.$documentListWrapper.remove();
    }
    if ($('.js-document-wrapper').length === 0) {
      $('.js-select-attach-document-message-wrapper').hide();
    }
  }
}

export {
  DeleteDocumentModal,
};
