const getActualHeight = (el) => {
  /* eslint-disable no-param-reassign */
  el.style.display = 'block';
  const height = `${ Math.floor(el.scrollHeight) }px`;

  el.style.display = '';
  /* eslint-disable no-param-reassign */

  return height;
};

export {
  getActualHeight,
};
