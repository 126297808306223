import $ from 'jquery';
import { Modal } from './modal';

class PreventMisapplicationModal {
  constructor({ modalId, $form }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
    });
    this.$messageForm = $form;
    this.$applyButton = null;
    this.$refuseScoutButton = null;
    this.$submitButton = null;
  }

  init = () => {
    this.modal.init();
    this.$submitButton = this.modal.$wrapper.find('.js-modal__submitter');
    this.$submitButton.on('click', (e) => {
      e.preventDefault();
      const replyType = $(e.currentTarget).data('reply-type');
      this.submit(replyType);
    });
  }

  submit = (replyType) => {
    this.forceUpdateMessageTypeTo(replyType);
    this.$submitButton.prop('disabled', true);
    this.$messageForm.off('submit').submit();
  }

  forceUpdateMessageTypeTo = (messageType) => {
    this.$messageForm.find('.js-select-reply-type').val(messageType);
  }

  show = () => {
    this.modal.show({});
  }
}

export {
  PreventMisapplicationModal,
};
