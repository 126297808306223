import $ from 'jquery';

const HIDDEN_CLASS_NAME = 'u-hidden';

class Toggle {
  constructor({ wrapper }) {
    this.$wrapper = $(wrapper);
    this.$showTrigger = this.$wrapper.find('.js-toggle__show-trigger');
    this.$hideTrigger = this.$wrapper.find('.js-toggle__hide-trigger');
    this.$target = this.$wrapper.find('.js-toggle__target');
  }

  init = () => {
    this.$showTrigger.on('click', (e) => {
      e.preventDefault();
      this.show();
    });
    this.$hideTrigger.on('click', (e) => {
      e.preventDefault();
      this.hide();
    });
  }

  show = () => {
    this.$target.removeClass(HIDDEN_CLASS_NAME);
    this.$showTrigger.addClass(HIDDEN_CLASS_NAME);
    this.$hideTrigger.removeClass(HIDDEN_CLASS_NAME);
  }

  hide = () => {
    this.$target.addClass(HIDDEN_CLASS_NAME);
    this.$showTrigger.removeClass(HIDDEN_CLASS_NAME);
    this.$hideTrigger.addClass(HIDDEN_CLASS_NAME);
  }
}

export {
  Toggle,
};
