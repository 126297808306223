import $ from 'jquery';
import { Modal } from './modal';

class IndeedEntryModal {
  constructor({
    modalId,
  }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
    });
  }

  init = () => {
    this.modal.init();
    if (this.modal.$wrapper.length > 0) {
      // indeed経由での応募でJMにlandingしてから即座にmodalが表示されるとユーザがどこにいるのか把握できなくなる可能性が高いと思われるため、
      // モーダルの表示は1000ms遅らせることでそのリスクを軽減する
      setTimeout(() => {
        this.modal.show({});
      }, 1000);
    }
  }
}

export {
  IndeedEntryModal,
};
