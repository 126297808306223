import $ from 'jquery';
import { Modal } from './modal';

class RefineSearchModal {
  constructor({ modalId, onHide, onShow }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      onHide,
      onShow,
      isContentHeightDynamic: true,
    });
  }

  init = () => {
    this.modal.init();
  }
}

export {
  RefineSearchModal,
};
