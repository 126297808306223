import * as yup from 'yup';

export const memberAgentAvailableSchema = {
  'member[agent_available]': {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      const value = $form.find('[name="member[agent_available]"]:checked').val();
      if (value === undefined) {
        return -1;
      }

      return value;
    },
    getStyleTargetElement: ($form: JQuery<HTMLElement>) => {
      return $form.find('[name="member[agent_available]"]').siblings('label');
    },
    validation: yup
      .number()
      .min(0, 'ジョブメドレーエージェントの利用有無を選択してください'), // .required()でundefinedが弾かれないため、.min(0, 'xx')でundefinedの時に渡される-1を弾くようにする
  },
};
