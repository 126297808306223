import $ from 'jquery';

class StorageButtonManager {
  constructor({ keep, favorite }) {
    this.keep = keep;
    this.favorite = favorite;
  }

  build = ({ type } = {}) => {
    const storageType = type || this.getType();

    switch (storageType) {
      case 'favorite':
        this.storage = this.favorite;
        break;
      case 'keep':
        this.storage = this.keep;
        break;
      default:
        return null;
    }

    return this.storage.init();
  }

  isKeep = () => {
    return [
      $(`.${ this.keep.triggerClassName }`).length > 0,
      $(`.${ this.keep.counterClassName }`).length > 0,
    ].some(v => v);
  }

  isFavorite = () => {
    return [
      $(`.${ this.favorite.triggerClassName }`).length > 0,
      $(`.${ this.favorite.counterClassName }`).length > 0,
    ].some(v => v);
  }

  getType = () => {
    if (this.isKeep()) {
      return 'keep';
    }
    if (this.isFavorite()) {
      return 'favorite';
    }

    return '';
  }

  sync = () => {
    if (!this.storage) {
      return this.build();
    }
    return this.storage.sync();
  }
}

export {
  StorageButtonManager,
};
