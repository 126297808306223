
// job_category_id: [
//    {
//      qualification_id: 'job_category_name'
//    }
//
// sync with JobCategoriesQualification::MUST_QUALIFICATION_MAP

const JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP = {
  1: [
    {
      1: '医師',
    },
  ],
  3: [
    {
      2: '看護師',
    }, {
      5: '准看護師',
    },
  ],
  5: [
    {
      4: '助産師',
    },
  ],
  6: [
    {
      3: '保健師',
    },
  ],
  7: [
    {
      13: '薬剤師',
    },
  ],
  8: [
    {
      11: '臨床検査技師',
    },
  ],
  10: [
    {
      17: '歯科衛生士',
    },
  ],
  11: [
    {
      18: '歯科技工士',
    },
  ],
  13: [
    {
      10: '診療放射線技師',
    },
  ],
  15: [
    {
      7: '作業療法士',
    },
  ],
  16: [
    {
      6: '理学療法士',
    },
  ],
  22: [
    {
      19: '介護支援専門員（ケアマネジャー）',
    },
  ],
  24: [
    {
      12: '臨床工学技士',
    },
  ],
  25: [
    {
      9: '言語聴覚士',
    },
  ],
  27: [
    {
      8: '視能訓練士',
    },
  ],
  30: [
    {
      15: '歯科医師',
    }, {
      16: '歯科医師（院長/分院長経験あり）',
    },
  ],
  32: [
    {
      29: '柔道整復師',
    },
  ],
  33: [
    {
      30: '鍼灸師',
    },
  ],
  34: [
    {
      31: 'あん摩マッサージ指圧師',
    },
  ],
  35: [
    {
      14: '登録販売者',
    },
  ],
  43: [
    {
      28: '保育士',
    },
  ],
  46: [
    {
      34: '幼稚園教論',
    },
  ],
  48: [
    {
      46: '自動車運転免許',
    },
  ],
};

export {
  JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP,
};
