import $ from 'jquery';

import { Modal } from './modal';

class SearchConditionSaveFormModal {
  constructor({ modalId, onShow }) {
    this.modalId = modalId;
    this.el = $(`.js-modal[data-modal-id="${ this.modalId }"]`);
    this.onShow = onShow;
    this.modal = new Modal({
      el: this.el,
      onShow: this.onShow,
    });
  }

  init = () => {
    this.modal.init();
  }

  show = () => {
    this.modal.show({});
  }
}

export {
  SearchConditionSaveFormModal,
};
