import $ from 'jquery';

class UpperLimitManager {
  static alert = (target) => {
    if (UpperLimitManager.isUpperLimitOver(target)) {
      window.alert(`選択できるチェックボックスは${ UpperLimitManager.getUpperLimit(target) }個までです`);// eslint-disable-line no-alert
      $(target).prop('checked', false);
    }
  }

  static getUpperLimit = (target) => {
    const $form = $(target).closest('form');

    return $form.data('upper-limit') || 40;
  }

  // 重複を除いたチェックボックスの数が上限を超えているかどうか(submit時の重複削除は別途行う必要がある)
  static isUpperLimitOver = (target) => {
    const $form = $(target).closest('form');
    const upperLimit = UpperLimitManager.getUpperLimit(target);

    // Map<チェックボックスのname, チェックボックスのnameに紐づくvalueのSet>
    const uniqNameAndValues = [...$form.find('input:checkbox:checked')].reduce((acc, cur) => {
      const name = $(cur).attr('name');
      const value = $(cur).val();
      const valuesTiedToCheckName = acc.get(name);

      const newValuesTiedToCheckName = valuesTiedToCheckName
        ? valuesTiedToCheckName.add(value)
        : new Set([value]);

      return acc.set(name, newValuesTiedToCheckName);
    }, new Map());

    const uniqCount = [...uniqNameAndValues.values()].reduce((acc, cur) => acc + cur.size, 0);

    return uniqCount > upperLimit;
  }
}

class CheckboxSynchronizer {
  constructor({ wrapper, type }) {
    this.$wrapper = $(wrapper);
    this.type = type;
    this.$checkboxes = this.$wrapper.find('input:checkbox');
  }

  static generateSelectorByType = ($target, $form, type) => {
    switch (type) {
      case 'data':
        return $form.find(`input:checkbox[data-checkbox-synchronizer-id="${ $target.data('checkbox-synchronizer-id') }"]`);
      case 'id':
        return $form.find(`input:checkbox[id="${ $target.attr('id') }"]`);
      default:
        return $form.find(`input:checkbox[value="${ $target.val() }"]`);
    }
  }

  static sync = (target, { type = 'value', form }) => {
    const $target = $(target);
    const $form = $(form).length > 0 ? $(form) : $target.closest('form');
    const $synchronizationCheckboxes = CheckboxSynchronizer.generateSelectorByType($target, $form, type);

    if (!$synchronizationCheckboxes.length) {
      return [];
    }

    if ($target.prop('checked')) {
      $synchronizationCheckboxes.prop('checked', true);
    } else {
      $synchronizationCheckboxes.prop('checked', false);
    }

    return $synchronizationCheckboxes;
  }

  static syncLazyLoaded = (target, { type = 'value', form }) => {
    const $target = $(target);
    const $form = $(form).length > 0 ? $(form) : $target.closest('form');
    const $synchronizationCheckboxes = CheckboxSynchronizer.generateSelectorByType($target, $form, type);
    const isChecked = $synchronizationCheckboxes.not($target).eq(0).prop('checked');

    $target.prop('checked', isChecked);
  }
}

export {
  UpperLimitManager,
  CheckboxSynchronizer,
};
