const removeDuplicated = (array) => {
  return [...new Set(array)];
};

const findDuplicated = (array) => {
  return array.reduce((accumulator, current, index) => {
    if (array.indexOf(current) !== index && accumulator.indexOf(current) < 0) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
};

const flatten = (array) => {
  return [].concat(...array);
};

export {
  removeDuplicated,
  findDuplicated,
  flatten,
};
