import $ from 'jquery';
import queryString from 'query-string';
import { Modal } from './modal';
import { emitter } from '../../util/event-emitter';

class AttachDocumentsModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
    });

    this.resumeId = '';
    this.resumeName = null;
    this.careerSheetId = '';
    this.careerSheetName = null;
    this.$resumeSelectBox = this.modal.$wrapper.find('.js-resume-select-box');
    this.$careerSheetSelectBox = this.modal.$wrapper.find('.js-career-sheet-select-box');
    this.$resumePreview = this.modal.$wrapper.find('.js-resume-preview');
    this.$resumeAttachedPreview = $('.js-attached-resume-preview');
    this.$careerSheetPreview = this.modal.$wrapper.find('.js-career-sheet-preview');
    this.$careerSheetAttachedPreview = $('.js-attached-careersheet-preview');
    this.$attachDocumentsButton = this.modal.$wrapper.find('.js-attach-documents');
    this.disabledSelectBoxClassName = this.modal.$wrapper.data('disabled-selectbox-class-name');
    this.disabledButtonClassName = this.modal.$wrapper.data('disabled-button-class-name');
    this.$closerButton = this.modal.$closer;
    this.$frame = this.modal.$frame;
  }

  init = (formManager) => {
    this.formManager = formManager;
    this.modal.init();
    this.$resumeSelectBox.on('change', () => {
      this.updateResume();
      this.syncResumePreview();
      this.syncAttachDocumentsButton();
    });
    this.$careerSheetSelectBox.on('change', () => {
      this.updateCareerSheet();
      this.syncCareerSheetPreview();
      this.syncAttachDocumentsButton();
    });
    this.$attachDocumentsButton.on('click', this.validateModal);
    emitter.on('createDocumentModal:create', ({ createdResume, createdCareersheet }) => {
      if (createdResume) {
        this.addCreatedResumeToSelectBox(createdResume);
      }
      if (createdCareersheet) {
        this.addCreatedCareersheetToSelectBox(createdCareersheet);
      }
    });

    this.$closerButton.on('click', this.clearValidateMessage);

    this.$frame.on('click', (e) => {
      if (e.target === this.$frame[0]) {
        this.clearValidateMessage();
      }
    });

    // sync state
    this.syncResumePreview();
    this.syncCareerSheetPreview();
    this.syncAttachDocumentsButton();
    const parsed = queryString.parse(window.location.search);
    if (parsed.select_document) {
      setTimeout(() => {
        this.modal.show({});
      }, 1000);
    }
  }

  validateModal = () => {
    this.formManager.validateForm({ onlyCount: false, eventType: 'click' }).then((res) => {
      this.formManager.updateValidationMessage(res);
      if (this.formManager.getErrors(res).length === 0) {
        this.attachDocuments();
      }
    });
  }

  show = ({ resumeId, careersheetId }) => {
    if (resumeId) {
      this.$resumeSelectBox.val(resumeId);
    } else {
      this.$resumeSelectBox.val('');
    }
    if (careersheetId) {
      this.$careerSheetSelectBox.val(careersheetId);
    } else {
      this.$careerSheetSelectBox.val('');
    }
    this.updateResume();
    this.updateCareerSheet();
    this.syncResumePreview();
    this.syncCareerSheetPreview();
    this.syncAttachDocumentsButton();

    this.modal.show({});
  }

  addCreatedResumeToSelectBox = (createdResume) => {
    this.$resumeSelectBox.append(`<option value="${ createdResume.id }">${ createdResume.name }</option>`);
    this.$resumeSelectBox.removeClass(this.disabledSelectBoxClassName);
  }

  addCreatedCareersheetToSelectBox = (createdCareersheet) => {
    this.$careerSheetSelectBox.append(`<option value="${ createdCareersheet.id }">${ createdCareersheet.name }</option>`);
    this.$careerSheetSelectBox.removeClass(this.disabledSelectBoxClassName);
  }

  updateResume = () => {
    this.resumeId = this.$resumeSelectBox.val();
    if (this.resumeId !== '') {
      this.resumeName = this.$resumeSelectBox.find('option:selected').text();
    }
  }

  updateCareerSheet = () => {
    this.careerSheetId = this.$careerSheetSelectBox.val();
    if (this.careerSheetId !== '') {
      this.careerSheetName = this.$careerSheetSelectBox.find('option:selected').text();
    }
  }

  syncResumePreview = () => {
    if (this.resumeId === '') {
      this.$resumePreview.addClass(this.disabledButtonClassName);
    } else {
      this.$resumePreview.attr('href', `/members/messages/resume/${ this.resumeId }`);
      this.$resumeAttachedPreview.attr('href', `/members/messages/resume/${ this.resumeId }`);
      this.$resumePreview.removeClass(this.disabledButtonClassName);
    }
  }

  syncCareerSheetPreview = () => {
    if (this.careerSheetId === '') {
      this.$careerSheetPreview.addClass(this.disabledButtonClassName);
    } else {
      this.$careerSheetPreview.attr('href', `/members/messages/careersheet/${ this.careerSheetId }`);
      this.$careerSheetAttachedPreview.attr('href', `/members/messages/careersheet/${ this.careerSheetId }`);
      this.$careerSheetPreview.removeClass(this.disabledButtonClassName);
    }
  }

  syncAttachDocumentsButton = () => {
    if (this.resumeId !== '' || this.careerSheetId !== '') {
      this.$attachDocumentsButton.removeClass(this.disabledButtonClassName);
    } else {
      this.$attachDocumentsButton.addClass(this.disabledButtonClassName);
    }
  }

  attachDocuments = () => {
    if (this.resumeId !== '') {
      const resume = { id: this.resumeId, name: this.resumeName };
      emitter.emit('attachDocumentsModal:attachDocuments', { resume });
    }
    if (this.careerSheetId !== '') {
      const careersheet = { id: this.careerSheetId, name: this.careerSheetName };
      emitter.emit('attachDocumentsModal:attachDocuments', { careersheet });
    }
    this.reset();
    this.modal.hide({});
  }

  reset = () => {
    // reset value
    this.resumeId = '';
    this.resumeName = null;
    this.$resumeSelectBox.val('');
    this.careerSheetId = '';
    this.careerSheetName = null;
    this.$careerSheetSelectBox.val('');

    // sync state
    this.syncResumePreview();
    this.syncCareerSheetPreview();
    this.syncAttachDocumentsButton();
  }

  clearValidateMessage = () => {
    this.reset();
    this.formManager.validateForm({ onlyCount: false, eventType: 'click' }).then((res) => {
      this.formManager.updateValidationMessage(res);
    });
  }
}

export {
  AttachDocumentsModal,
};
