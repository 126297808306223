import Cookie from 'js-cookie';
import $ from 'jquery';
import { Modal } from './modules/modal';
import { isIE11 } from './util/browser';
import { AsyncJobOfferAggregation } from './async-job-offer-aggregation';

class Keep {
  constructor({ shouldAttachEvent = true } = {}) {
    this.shouldAttachEvent = shouldAttachEvent;
  }

  $target = null
  $targetIcon = null
  $targetText = null
  $targetAnimation = null
  $checkboxForModalHidden = null
  jobOfferId = 0
  animation = null
  animationList = {}
  keptClassName = 'c-button__keep-icon--hold'
  JMVID = Cookie.get('JMVID')
  KEEP_COUNT_KEY = 'keep_count'
  triggerClassName = 'js-keep-button'
  counterClassName = 'js-keep-counter'
  MAX = 20
  // 「キープした求人において、キープ解除した際に、キープボタンが正常に表示されない #33073」の対応で追加
  isFirstSync = true

  init = () => {
    this.setModalByKeepCount();
    this.$checkboxForModalHidden = this.$modal.find('.js-modal-for-keep__checkbox');
    this.sync();

    if (this.shouldAttachEvent) {
      this.attachEvent();
    }
  }

  attachEvent = () => {
    $(document.body).on('click', '.js-keep-button', (e) => {
      e.preventDefault();
      this.update(e.currentTarget);

      if (!isIE11) {
        this.setAnimation(e.currentTarget);
      }
    });

    this.$checkboxForModalHidden.on('change', this.saveModalHidden);
  }

  setAnimation = (buttonDom) => {
    const animationDom = buttonDom.getElementsByClassName('js-keep-animation')[0];
    if (this.animationList[this.jobOfferId] === undefined) {
      this.animationList[this.jobOfferId] = this.createAnimation(animationDom);
      $(animationDom).addClass('c-button__keep-animation--animation-added');
    }
    this.animation = this.animationList[this.jobOfferId];
  }

  createAnimation = (animationDom) => {
    // eslint-disable-next-line no-undef
    return bodymovin.loadAnimation({
      container: animationDom,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: 'https://cdn.job-medley.com/images/lottie/star.json',
    });
  }

  isModalHiddenPermanently = () => {
    return Cookie.get('keep_modal_hidden') === 'true';
  }

  saveModalHidden = (e) => {
    $.ajax({
      type: 'POST',
      url: '/api/users/keep_job_offers/keep_modal_hidden',
      data: {
        keep_modal_hidden: e.target.checked,
      },
    });
  }

  sync = async () => {
    if (!this.isFirstSync && AsyncJobOfferAggregation.initialAggregationCount > 0) {
      // 複数回実行される内の最初と最後の実行時以外はファーストビュー以外のキープボタンを読み込み中にして早期リターン
      $('.js-job-offer-aggregation .js-keep-button .js-keep-text').text('読み込み中…');
      return;
    }

    if (this.isFirstSync && AsyncJobOfferAggregation.initialAggregationCount > 0) {
      // 複数回実行される内の最初の実行の場合はキープボタンを読み込み中にして処理を続行、後続でファーストビューのキープボタンが更新される（ファーストビュー以外のキープボタンは非同期で取得するためまだ存在しない）
      $('.js-keep-button .js-keep-text').text('読み込み中…');
    }

    this.isFirstSync = false;

    const response = await $.ajax({
      type: 'GET',
      url: '/api/users/keep_job_offers/status',
      data: {
        jmvid: this.JMVID,
      },
    });

    $('.js-keep-button .js-keep-text').each((index, element) => {
      if ($(element).text() === '読み込み中…') {
        $(element).text('キープする');
      }
    });

    if (!response.data.length) {
      return;
    }

    const keptButtons = [...document.querySelectorAll('.js-keep-button')].filter((button) => {
      const jobOfferId = $(button).data('job-offer-id');
      return response.data.includes(`${ jobOfferId }`);
    });
    keptButtons.forEach((currentButton) => {
      const $currentIcon = $(currentButton).find('.js-keep-icon');
      const $currentText = $(currentButton).find('.js-keep-text');
      this.updateButtonToKept($currentIcon, $currentText);
    });
    this.count(response.count);
  }

  isKeptJobOffer = ($target) => {
    return $target.hasClass(this.keptClassName);
  }

  hasResponseMessage = (response) => {
    return response && response.responseJSON && response.responseJSON.message;
  }

  update = (target) => {
    this.$target = $(target);
    this.$targetIcon = this.$target.find('.js-keep-icon');
    this.$targetText = this.$target.find('.js-keep-text');
    this.$targetAnimation = this.$targetIcon.find('.js-keep-animation');
    this.$targetAnimation1 = this.$targetIcon.find('.js-keep-animation1');
    this.jobOfferId = this.$target.data('job-offer-id');

    if (this.isKeptJobOffer(this.$targetIcon)) {
      return this.unkeep();
    }
    return this.keep();
  }

  keep = async () => {
    let response = null;
    let type;
    if (this.isReachedKeepCount()) {
      type = 'register_reached_keep_modal';
    } else {
      type = 'register_keep_modal';
    }
    this.setModalByKeepCount();

    try {
      response = await $.ajax({
        method: 'POST',
        url: '/api/users/keep_job_offers',
        data: {
          job_offer_id: this.jobOfferId,
          jmvid: this.JMVID,
          form_type: type,
        },
      });
      if (!isIE11 && this.$targetAnimation.hasClass('c-button__keep-animation--animation-added')) {
        this.$targetIcon.addClass('c-button__keep-icon--transparent');
        this.$targetIcon.find('.js-keep-animation').removeClass('c-button__keep-animation--no-hold');
        this.animation.play();
        this.animation.playSegments([0, 100], true);
      }
    } catch (error) {
      if (this.hasResponseMessage(error)) {
        /* eslint-disable no-alert */
        alert(error.responseJSON.message);
      } else {
        alert('キープリストに追加できませんでした');
        /* eslint-enable no-alert */
      }
      throw new Error(error);
    }
    if (Object.keys(response.data).length > 0) {
      [...document.querySelectorAll('.js-transition-to-registration-link')].forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.href = `${el.href}&job_category_id=${response.data.job_category_id}&employment_type=${response.data.employment_type}`;
      });
    }
    if (this.isReachedKeepCount()) {
      await this.modal.show({});
    } else {
      const keptButtons = [...document.querySelectorAll('.js-keep-button')].filter((button) => {
        const jobOfferId = $(button)
          .data('job-offer-id');
        return `${this.jobOfferId}` === (`${jobOfferId}`);
      });
      await keptButtons.forEach(async (currentButton) => {
        const $currentIcon = $(currentButton)
          .find('.js-keep-icon');
        const $currentText = $(currentButton)
          .find('.js-keep-text');
        await this.updateButtonToKept($currentIcon, $currentText);
      });
      if (!this.isModalHiddenPermanently()) {
        await this.modal.show({});
      }
    }
    await this.count(response.count);
  }

  unkeep = async () => {
    let response = null;

    try {
      response = await $.ajax({
        method: 'DELETE',
        url: '/api/users/keep_job_offers',
        data: {
          job_offer_id: this.jobOfferId,
          jmvid: this.JMVID,
        },
      });
      if (!isIE11 && this.$targetAnimation.hasClass('c-button__keep-animation--animation-added')) {
        this.$targetIcon.removeClass('c-button__keep-icon--transparent');
        this.$targetIcon.find('.js-keep-animation').addClass('c-button__keep-animation--no-hold');
      }
    } catch (error) {
      if (this.hasResponseMessage(error)) {
        /* eslint-disable no-alert */
        alert(error.responseJSON.message);
      } else {
        alert('キープリストから削除できませんでした');
        /* eslint-enable no-alert */
      }
      throw new Error(error);
    }
    const keptButtons = [...document.querySelectorAll('.js-keep-button')].filter((button) => {
      const jobOfferId = $(button).data('job-offer-id');
      return `${ this.jobOfferId }` === (`${ jobOfferId }`);
    });
    await keptButtons.forEach(async (currentButton) => {
      const $currentIcon = $(currentButton).find('.js-keep-icon');
      const $currentText = $(currentButton).find('.js-keep-text');
      await this.updateButtonToUnkept($currentIcon, $currentText);
    });
    await this.count(response.count);
  }

  count = (count) => {
    $(`.${ this.counterClassName }`).text(count > 0 ? count : '');
    Cookie.set(this.KEEP_COUNT_KEY, count, { expires: 7 });
  }

  updateButtonToKept = ($targetIcon, $targetText) => {
    $targetIcon.addClass(this.keptClassName);
    $targetText.text('キープ済み');
  }

  updateButtonToUnkept = ($targetIcon, $targetText) => {
    $targetIcon.removeClass(this.keptClassName);
    $targetText.text('キープする');
  }

  setModalByKeepCount = () => {
    if (this.isReachedKeepCount()) {
      this.$modal = $('.js-modal-for-reached-keep');
    } else {
      this.$modal = $('.js-modal-for-keep');
    }
    this.modal = new Modal({ el: this.$modal[0] });
    if (this.shouldAttachEvent) {
      this.modal.init();
    }
  }

  isReachedKeepCount = () => {
    return Cookie.get(this.KEEP_COUNT_KEY) >= this.MAX;
  }
}

export {
  Keep,
};
