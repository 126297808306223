
// job_category_id: [
//    {
//      qualification_id: 'job_category_name'
//    }
//
// sync with JobCategoriesQualification::MUST_QUALIFICATION_FOR_APPLICATION_MAP
// app/models/job_categories_qualification.rb

const JOB_CATEGORY_REQUIRED_QUALIFICATION_WITH_VALIDATION_MAP = {
  14: [
    {
      26: '臨床心理士',
    },
    {
      59: '公認心理師',
    },
  ],
  49: [
    {
      47: '福祉用具専門相談員',
    },
  ],
};

export {
  JOB_CATEGORY_REQUIRED_QUALIFICATION_WITH_VALIDATION_MAP,
};
