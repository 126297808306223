const PATTERNS = {
  POSTAL_CODE: /^\d{7}$/,
  MAIL_ADDRESS: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  HIRAGANA: /^(?:[ぁ-ゞ]+)*$/,
  ADDRESS_HIRAGANA: /^(?:[ぁ-ゞ]|[０-９]|[−‐－ー―-]|[0-9]+)*$/,
  TEL: /^\d{10,11}$/,
  BANNED_WORDS: /(\d{10,13})|(\d{2,4}\-\d{2,4}\-\d{2,4})|(\d{2,4}\-\d{8})|(http)|(.+@.+\.)/, // eslint-disable-line
  MISAPPLICATION_KEYWORDS: /辞退|お断り|遠慮/,
  BANK_CODE: /^\d{4}$/,
  BANK_BRANCH_CODE: /^\d{3}$/,
  BANK_ACCOUNT_CODE: /^\d{7}$/,
  BANK_ACCOUNT_NAME: /^[ァ-ヴＡ-Ｚ０-９．（）ー‐－]+$/,
};

export {
  PATTERNS,
};
