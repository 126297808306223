import * as yup from 'yup';
import { memberSchema } from './member-schema';

export const memberRegistrationSchema = {
  ...memberSchema,

  'member[member_desired_employment_types_attributes][][employment_type]': {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      return $form.find('.js-form-manager__field--employment-type:checked').length;
    },
    getStyleTargetElement: ($form: JQuery<HTMLElement>) => {
      return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
    },
    validation: yup
      .string()
      .test('required', '希望勤務形態を選択してください', (checkedLength) => {
        return checkedLength > 0;
      }),
    count: true,
  },

  'member[job_category_ids][]': {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      return $form.find('.js-form-manager__field-job-category-id:checked').length;
    },
    validation: yup
      .string()
      .test('required', '希望職種を選択してください', (checkedLength) => {
        return checkedLength > 0;
      }),
    count: true,
  },
};
