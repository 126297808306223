const getDimension = (el) => {
  const { top } = el.getBoundingClientRect();
  const viewportTop = document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
  const viewportHeight = window.innerHeight;
  const viewportBottom = viewportTop + viewportHeight;
  const elScrollTop = Math.round(top + viewportTop);
  const elScrollBottom = Math.round(elScrollTop + el.offsetHeight);

  return {
    elScrollTop,
    elScrollBottom,
    viewportTop,
    viewportBottom,
  };
};

const isInViewport = (el) => {
  const { elScrollTop, elScrollBottom, viewportTop, viewportBottom } = getDimension(el);

  return (elScrollTop < viewportBottom) && (elScrollBottom > viewportTop);
};

const isAboveOutOfViewport = (el) => {
  const { elScrollBottom, viewportTop } = getDimension(el);

  return elScrollBottom < viewportTop;
};

const isBottomOutOfViewport = (el) => {
  const { elScrollTop, viewportBottom } = getDimension(el);

  return elScrollTop > viewportBottom;
};

const isAboveOutOfViewportBottom = (el) => {
  const { elScrollTop, viewportBottom } = getDimension(el);

  return elScrollTop < viewportBottom;
};

export {
  isInViewport,
  isAboveOutOfViewport,
  isBottomOutOfViewport,
  isAboveOutOfViewportBottom,
};
