import $ from 'jquery';

class LinkExtender {
  init = () => {
    // safariの https://stackoverflow.com/questions/14795944/jquery-click-events-not-working-in-ios/16006333#16006333 を回避するために cursor: pointer;
    // 使いたいけど、カード自体にcursor: ponter;が付与されていると内包するボタン要素でのtap-highlight-colorが効かないので、document.bodyを使用せずに回避する
    $('.js-wrapper').on('click', '.js-link-extender', (e) => {
      this.linkTo(e);
    });
  }

  isAnchorElementOrChild = (target) => {
    if (target.tagName.toUpperCase() === 'A' || $(target).closest('a').length > 0) {
      return true;
    }
    return false;
  }

  isOpenWithNewTab = (shiftKey, metaKey) => {
    return shiftKey || metaKey;
  }

  isDisabled = (target) => {
    return $(target).prop('disabled');
  }

  /* eslint-disable consistent-return */
  linkTo = (e) => {
    const {
      currentTarget,
      target,
      shiftKey,
      metaKey,
    } = e;

    if (this.isDisabled(target)) {
      return false;
    }
    if (this.isAnchorElementOrChild(target)) {
      return;
    }

    const url = $(currentTarget).find('.js-link-extender__target').attr('href');

    if (this.isOpenWithNewTab(shiftKey, metaKey)) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }
  /* eslint-enable consistent-return */
}

export {
  LinkExtender,
};
