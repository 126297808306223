import $ from 'jquery';
import Cookie from 'js-cookie';
import { Modal } from './modal';

class NotificationModal {
  constructor({ modalId, cookieId }) {
    this.modalId = modalId;
    this.cookieId = cookieId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
    });
  }

  init = () => {
    if (Cookie.get('modal_id') === this.cookieId) {
      this.modal.init();
      this.modal.show({});
      Cookie.remove('modal_id');
    }
  }
}

export {
  NotificationModal,
};
