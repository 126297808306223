import $ from 'jquery';
import { Modal } from './modal';
import { emitter } from '../../util/event-emitter';

class CopyDocumentModal {
  constructor({ modalId }) {
    this.modalId = modalId; // copy-resume or copy-careersheet
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      beforeShow: this.beforeShow,
    });

    this.documentId = null;
    this.documentName = null;
    this.$submitButton = null;
    this.$documentListItemName = null;
    this.changedNotificationModal = new Modal({ el: $('.js-modal[data-modal-id="changed-notification"]') });
  }

  beforeShow = ({ trigger }) => {
    this.$trigger = $(trigger);
    this.documentId = this.$trigger.data('document-id');
    this.$documentListItemName = this.$trigger.closest('.js-document-list__item').find('.js-document-name');
    this.documentName = this.$documentListItemName.text();
  }

  init = () => {
    this.modal.init();
    this.$submitButton = this.modal.$wrapper.find('.js-modal__submitter');
    this.$submitButton.on('click', (e) => {
      e.preventDefault();
      this.handleSubmit();
    });
    this.changedNotificationModal.init();
  }

  saveDocument = async () => {
    if (this.isResume) {
      this.documentName = $('.js-copy-file-name-field').val();
    }

    await $.ajax({
      type: 'POST',
      url: this.getAPIUrl(),
      data: {
        name: this.documentName,
      },
    }).then((response) => {
      if (response?.redirect_url) {
        window.location.href = response.redirect_url;
        setTimeout(() => this.enableSubmitButton, 3000);
      } else {
        this.enableSubmitButton();
        this.$documentListItemName.text(this.documentName);
        this.modal.hide({});
        this.changedNotificationModal.show({});
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }).catch((error) => {
      /* eslint-disable no-alert */
      alert('保存できませんでした');
      /* eslint-enable no-alert */
      throw new Error(error);
    });
  }

  getAPIUrl = () => {
    if (this.isResume()) {
      return `/api/users/resumes/copy/${ this.documentId }`;
    }
    return `/api/users/careersheets/copy/${ this.documentId }`;
  }

  handleSubmit = () => {
    if (this.isResume()) {
      emitter.emit('formManager:validateForm', {
        callback: () => {
          this.$submitButton.prop('disabled', true);
          this.saveDocument();
        },
      });
    } else {
      this.saveDocument();
    }
  };

  isResume = () => {
    return this.modalId === 'copy-resume';
  }

  enableSubmitButton = () => {
    this.$submitButton.prop('disabled', false);
  }
}

export {
  CopyDocumentModal,
};
