import * as yup from 'yup';
import $ from 'jquery';

export const memberQualificationsSchema = {
  'member[qualifications][]': {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      return $form.find('.js-form-manager__field--required-qualification-by-job-category').filter(':checked');
    },
    validation: yup
      .object()
      .test('check_qualification', '応募条件を満たす資格・免許が正しく選択されていません。すでにお持ち、あるいは取得予定の資格・免許がある場合はチェックしてください。', (value) => {
        const errorMessageDuplicatedQualification = $('.js-duplicated-qualification-by-job-category-with-validation__error-msg').text();
        const errorMessageRequiredQualification = $('.js-required-qualification-by-job-category-with-validation__error-msg').text();

        if (errorMessageDuplicatedQualification.length > 0) {
          return false;
        }

        if (errorMessageRequiredQualification.length > 0) {
          return false;
        }

        if (value.length < 1) {
          return false;
        }

        return true;
      }),
    count: true,
  },
  'member[qualifications_acquisition_scheduled][]': {
    validation: yup
      .string()
      .test('check_qualifications_acquisition_scheduled', '保有資格・免許と取得予定の資格・免許は違う資格を選択してください。', () => {
        const errorMessageDuplicatedQualification = $('.js-duplicated-qualification-by-job-category-with-validation__error-msg').text();

        if (errorMessageDuplicatedQualification.length > 0) {
          return false;
        }

        return true;
      }),
  },
};
