const convertNullValue = (value) => {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
};

const toHankaku = (value) => {
  let word = convertNullValue(value).trim();
  if (word === '') {
    return '';
  }

  word = word.replace(/[！＂＃＄％＆＇（）＊＋，－．／０-９：；＜＝＞？＠Ａ-Ｚ［＼］＾＿｀ａ-ｚ｛｜｝～]/g, (s) => {
    String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  return word.replace(/[−‐－ー―]/g, '-');
};

export {
  convertNullValue,
  toHankaku,
};
