import $ from 'jquery';
import { throttle } from 'throttle-debounce';

class HeadRoom {
  constructor(el) {
    this.$el = $(el);
  }

  threshold = 200;

  cachedScrollTop = 0;

  currentScrollTop = 0;

  init = () => {
    if (this.$el.data('head-room-disabled')) {
      return;
    }

    // #idのページ内リンク直接urlに指定して遷移した時にscrollイベントが正常に発生しないケースがあり
    // （iOS safari のみで起きるが、根本原因不明なのでuserAgentで制限はしない. 恐らく一定のサイズ以上のDOM数になるとページ内リンクのscrollの挙動壊れる）、
    // ヘッダが被ってしまうのを避けるために location.hashとscrollTopの値見て強制的にヘッダ非表示にする
    if (location.hash && (document.body.scrollTop || document.documentElement.scrollTop)) {
      this.hide();
    }
    $(window).on('scroll', throttle(300, this.toggle));
  }

  isPositionToHide = () => {
    if (this.currentScrollTop > this.cachedScrollTop) {
      if (this.currentScrollTop >= this.threshold) {
        return true;
      }
      return false;
    }
    return false;
  }

  toggle = () => {
    this.currentScrollTop = $(window).scrollTop();

    if (this.isPositionToHide()) {
      this.hide();
    } else {
      this.show();
    }
    this.cachedScrollTop = this.currentScrollTop;
    return this.cachedScrollTop;
  }

  hide = () => {
    this.$el.addClass('c-header--hidden');
  }

  show = () => {
    this.$el.removeClass('c-header--hidden');
  }
}

export {
  HeadRoom,
};
