import $ from 'jquery';

class AsyncModalStations {
  static isAvailable = (targetBody) => {
    const $targetBody = $(targetBody);

    return !!$targetBody.data('line-id');
  }

  static inject = async ({ targetBody }) => {
    const $targetBody = $(targetBody);

    if (AsyncModalStations.isFetchedAlready($targetBody)) {
      return;
    }
    const response = await AsyncModalStations.fetch($targetBody);

    if (response) {
      await $targetBody.append(response);
      AsyncModalStations.markAsFetched($targetBody);
    }
  }

  static fetch = async ($targetBody) => {
    let response;

    try {
      response = await $.ajax({
        type: 'GET',
        url: `/api/users/line_station_modal/lines/${ $targetBody.data('line-id') }/stations/`,
        data: {
          job_category_code: $targetBody.data('job-category-code'),
          employment_type: $targetBody.data('employment-type'),
          feature: $targetBody.data('feature'),
          special_search_key: $targetBody.data('special-search-key'),
          is_facility: $targetBody.data('is-facility'),
          station_ids: $targetBody.data('station-ids'),
          prefecture_id: $targetBody.data('prefecture-id'),
        },
      });
    } catch (err) {
      throw err;
    }

    return response;
  }

  static markAsFetched = ($targetBody) => {
    $targetBody.addClass('js-is-stations-fetched');
  }

  static isFetchedAlready = ($targetBody) => {
    return $targetBody.hasClass('js-is-stations-fetched');
  }
}

export {
  AsyncModalStations,
};
