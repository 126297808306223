import $ from 'jquery';

class CityOptionsGenerator {
  constructor({ prefecture, city }) {
    this.$prefecture = $(prefecture);
    this.$city = $(city);
    this.defaultDateOption = null;
  }

  init = async () => {
    if (this.$prefecture.length < 1 || this.$city.length < 1) {
      return;
    }
    this.defaultDateOption = this.$city.children().eq(0)[0].outerHTML;

    this.$prefecture.on('change', async () => {
      await this.generate(this.$prefecture.val());
    });
  }

  updatePrefValue = async (prefectureId, cityId) => {
    const index = Array
      .from(this.$prefecture.children())
      .findIndex(opt => opt.value === prefectureId);
    this.$prefecture.prop('selectedIndex', index > -1 ? index : 0);
    await this.generate(prefectureId, cityId);
  }

  updateCityValue = async (cityId) => {
    if (cityId) {
      const index = Array
        .from(this.$city.children())
        .findIndex(opt => opt.value === cityId);
      await this.$city.prop('selectedIndex', index > -1 ? index : 0);
    } else {
      await this.$city.prop('selectedIndex', 0);
    }
  }

  generate = async (prefectureId, cityId) => {
    const response = await $.ajax({
      type: 'GET',
      url: '/api/cities',
      data: {
        prefecture_id: prefectureId,
      },
    });

    const options = [{ id: 0, name: undefined }, ...response.cities].map((city) => {
      if (city.id === 0) {
        return this.defaultDateOption;
      }
      return `<option value="${ city.id }" data-lat="${ city.lat }" data-lon="${ city.lon }">${ city.name }</option>`;
    });

    await this.$city.empty().append(options);
    await this.updateCityValue(cityId);
  }
}

export {
  CityOptionsGenerator,
};
