import $ from 'jquery';

class Dropdown {
  constructor(wrapper = document.body) {
    this.$wrapper = $(wrapper);
    this.$bodies = this.$wrapper.find('.js-dropdown__body');
    this.bodyHiddenClassName = this.$wrapper.find('.js-dropdown__body').eq(0).data('dropdown-hidden-class-name');
  }

  init = () => {
    this.$wrapper.on('click', '.js-dropdown__trigger', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.toggle(e);
    });
    $(document.body).on('click', this.hide);
  }

  toggle = (e) => {
    const $trigger = $(e.target);
    const $body = $trigger.next('.js-dropdown__body');
    const isHidden = $body.hasClass(this.bodyHiddenClassName);

    this.$bodies.addClass(this.bodyHiddenClassName);
    if (isHidden) {
      $body.removeClass(this.bodyHiddenClassName);
    }
  }

  hide = () => {
    const $shownBody = this.$bodies.not(`.${ this.bodyHiddenClassName }`);

    $shownBody.addClass(this.bodyHiddenClassName);
  }
}

export {
  Dropdown,
};
