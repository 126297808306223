import { isIOS } from '../../util/device';

const isThisIOS = isIOS();

class FootBarLiftingPreventer {
  constructor({ $wrapper, $footBar, selector = 'select' }) {
    this.$wrapper = $wrapper;
    this.$footBar = $footBar;
    this.selector = selector;
  }

  init = () => {
    // iOS ではキーボード出現時にposition: fixed;の固定フッターが適切な位置に配置されず入力の妨げになりうる挙動をするので、キーボード出現中は固定フッターを非表示にしておく
    if (!isThisIOS) {
      return;
    }
    this.$wrapper
      .on('focus', this.selector, this.hideFoot)
      .on('blur', this.selector, this.showFoot);
  }

  hideFoot = () => {
    this.$footBar.addClass('u-hidden');
  }

  showFoot = () => {
    this.$footBar.removeClass('u-hidden');
  }
}

export {
  FootBarLiftingPreventer,
};
