import $ from 'jquery';
import { JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP } from './constants/required-qualification';

class DuplicatedQualificationByJobCategory {
  constructor(wrapper) {
    this.$wrapper = $(wrapper);
    this.$checkboxes = this.$wrapper.find('.js-required-qualification-by-job-category__checkbox');
    this.$errorMessage = this.$wrapper.find('.js-duplicated-qualification-by-job-category__error-msg');
  }

  init = () => {
    this.update();
    this.$wrapper.on('change', '.js-required-qualification-by-job-category__checkbox', this.update);
  }

  update = () => {
    const text = this.isValid() ? '' : '保有資格・免許と取得予定の資格・免許は違う資格を選択してください。';
    this.$errorMessage.text(text);
  }

  createCompared = () => {
    const checkedQualificationIds = [
      ...(this.$checkboxes.filter(':checked')),
    ].map(v => v.value).sort();

    return {
      checkedQualificationIds,
    };
  }

  isValid = () => {
    const { checkedQualificationIds } = this.createCompared();
    const duplicatedQualificationIds = checkedQualificationIds.filter((x, i, self) => {
      return self.indexOf(x) === i && i !== self.lastIndexOf(x);
    });

    if (duplicatedQualificationIds.length < 1) {
      return true;
    }

    return false;
  }
}

class DuplicatedQualificationByJobCategoryForValidateQualifications extends DuplicatedQualificationByJobCategory {
  constructor(wrapper) {
    super();
    this.$wrapper = $(wrapper);
    this.$checkboxes = this.$wrapper.find('.js-required-qualification-by-job-category-with-validation__checkbox');
    this.$errorMessage = this.$wrapper.find('.js-duplicated-qualification-by-job-category-with-validation__error-msg');
  }

  init = () => {
    this.update();
    this.$wrapper.on('change', '.js-required-qualification-by-job-category-with-validation__checkbox', this.update);
  }
}

export {
  DuplicatedQualificationByJobCategory,
  DuplicatedQualificationByJobCategoryForValidateQualifications,
};
