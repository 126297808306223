import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import $ from 'jquery';

const hiddenMobileClass = 'u-hidden@mobile';
const menuButtonInvisibleClass = 'c-button-close--invisible';
const menuInvisibleClass = 'c-drawer--invisible';
const overlayInvisibleClass = 'c-overlay--invisible';
const $sideMenu = $('.js-slide-menu-container');
const $drawerInner = $sideMenu.find('.js-drawer__inner');
const $background = $('.js-slide-menu-background');
const $closeButton = $('.js-slide-menu-close-button');
const $openButton = $('.js-slide-menu-open-button');

function showBackground() {
  $background.removeClass(`${hiddenMobileClass} ${overlayInvisibleClass}`);
}

function hideBackground() {
  $background.addClass(overlayInvisibleClass);
  $background.one('transitionend', () => {
    $background.addClass(hiddenMobileClass);
  });
}

function showMenu() {
  disableBodyScroll($drawerInner[0]);
  $drawerInner[0].scrollTop = 0;
  showBackground();
  $closeButton.removeClass(menuButtonInvisibleClass);
  $sideMenu.removeClass(menuInvisibleClass);
}

function hideMenu() {
  hideBackground();
  $closeButton.addClass(menuButtonInvisibleClass);
  $sideMenu.addClass(menuInvisibleClass);
  enableBodyScroll($drawerInner[0]);
}

export const sideMenuManager = () => {
  $openButton.on('click', (e) => {
    e.preventDefault();
    showMenu();
  });

  $closeButton.on('click', (e) => {
    e.preventDefault();
    hideMenu();
  });

  $background.on('click', (e) => {
    e.preventDefault();
    hideMenu();
  });
};
