import $ from 'jquery';

class OnetimeDisplaySwitcher {
  constructor(el) {
    this.$wrapper = $(el);
    this.$trigger = this.$wrapper.find('.js-onetime-display-switcher__trigger');
    this.$target = this.$wrapper.find('.js-onetime-display-switcher__target');
  }

  init = () => {
    this.$trigger.on('click', (e) => {
      e.preventDefault();
      this.show();
    });
  }

  show = () => {
    this.$trigger.closest('.js-onetime-display-switcher__trigger-outer').addClass('u-hidden');
    this.$target.removeClass('u-hidden');
  }
}

export {
  OnetimeDisplaySwitcher,
};
