import $ from 'jquery';
import { throttle } from 'throttle-debounce';
import { isAboveOutOfViewport, isAboveOutOfViewportBottom, isBottomOutOfViewport } from './util/viewport';

class FootBar {
  constructor(el, { upperLandmark, lowerLandmark, secondUpperLandmark }) {
    this.$el = $(el);
    this.upperLandmark = document.querySelector(upperLandmark);
    this.lowerLandmark = document.querySelector(lowerLandmark);
    this.secondUpperLandmark = document.querySelector(secondUpperLandmark);
    this.hiddenClassName = this.$el.data('foot-bar-hidden-class-name');
    this.$removable = this.$el.find('.js-foot-bar__removable');
  }

  init = () => {
    if (this.$el.length < 1) {
      return;
    }

    $(window).on('scroll', throttle(300, this.toggle));
  }

  toggleByUpperOnly = () => {
    if (isAboveOutOfViewport(this.upperLandmark)) {
      this.$el.removeClass(this.hiddenClassName);
      this.hideRemovable();
    } else {
      this.$el.addClass(this.hiddenClassName);
    }
  }

  toggleByLowerOnly = () => {
    if (isBottomOutOfViewport(this.lowerLandmark)) {
      this.$el.removeClass(this.hiddenClassName);
      this.hideRemovable();
    } else {
      this.$el.addClass(this.hiddenClassName);
    }
  }

  toggleByUpperAndLower = () => {
    if (isAboveOutOfViewport(this.upperLandmark) && isBottomOutOfViewport(this.lowerLandmark)) {
      this.$el.removeClass(this.hiddenClassName);
      this.hideRemovable();
    } else {
      this.$el.addClass(this.hiddenClassName);
    }
  }

  toggleBySecondUpperAndLower = () => {
    if (isAboveOutOfViewportBottom(this.secondUpperLandmark) && isBottomOutOfViewport(this.lowerLandmark)) {
      this.$el.removeClass(this.hiddenClassName);
      this.hideRemovable();
    } else {
      this.$el.addClass(this.hiddenClassName);
    }
  }

  toggle = () => {
    if (this.secondUpperLandmark) {
      return this.toggleBySecondUpperAndLower();
    }
    if (!this.lowerLandmark) {
      return this.toggleByUpperOnly();
    }
    if (!this.upperLandmark) {
      return this.toggleByLowerOnly();
    }
    return this.toggleByUpperAndLower();
  }

  hideRemovable = () => {
    if (this.$removable.length < 1) {
      return;
    }
    setTimeout(() => {
      this.$removable.addClass('u-hidden');
    }, 4500);
  }
}

export {
  FootBar,
};
