import { emitter } from './util/event-emitter';

const loadFacebookPageScript = (facebookAppId) => {
  /* eslint-disable */
  ((d, s, id) => {
    if (d.getElementById(id)) {
      return;
    }

    let js,
      fjs = d.getElementsByTagName(s)[0];
    js = d.createElement(s);
    js.id = id;
    js.src = `//connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v2.5&appId=${ facebookAppId }`;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
  /* eslint-enable */
};

export const loadGoogleMapsApi = ({ key }) => {
  const promise = new Promise((resolve) => {
    const CALLBACK = 'googleMapsApiCallback';
    const API_VERSION = 'weekly';
    const src = `//maps.googleapis.com/maps/api/js?v=${ API_VERSION }&key=${ key }&callback=${ CALLBACK }&libraries=marker&loading=async`;

    window[CALLBACK] = () => {
      resolve(window.google.maps);
    };

    const script = document.createElement('script');

    script.src = src;
    document.head.appendChild(script);
  });

  return promise;
};

class LazyImage {
  static load = () => {
    if (document.querySelectorAll('.js-lazy-image').length < 1) {
      return;
    }

    import('lazysizes').then((lazySizes) => {
      /* eslint-disable no-param-reassign */
      lazySizes.cfg.lazyClass = 'js-lazy-image';
      lazySizes.cfg.loadingClass = 'js-lazy-image-loading';
      /* eslint-enable no-param-reassign */

      window.addEventListener('lazybeforeunveil', (e) => {
        LazyImage.loadScripts({ dataset: e.target.dataset });
      });
    });
  }

  static loadScripts = ({ dataset }) => {
    if (dataset.facebookPage) {
      loadFacebookPageScript(dataset.facebookAppId);
    }
    if (dataset.googlePublisherTag) {
      window.googletag.cmd.push(() => {
        window.googletag.pubads().refresh();
      });
    }
    if (dataset.googleMapsApiKey) {
      loadGoogleMapsApi({
        key: dataset.googleMapsApiKey,
      }).then((googleMaps) => {
        emitter.emit('GoogleMaps:load', {
          googleMaps,
        });
      });
    }
    if (dataset.jobCategoryTrendGraph) {
      emitter.emit('JobCategoryTrendGraph:load');
    }

    if (dataset.userVoiceImportantPointGraph) {
      emitter.emit('UserVoiceImportantPointGraph:load');
    }

    if (dataset.jobOfferCountAndMemberCountGraph) {
      emitter.emit('jobOfferCountAndMemberCountGraph:load');
    }
  }
}

export {
  LazyImage,
};
