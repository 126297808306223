import $ from 'jquery';
import { JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP } from './constants/required-qualification';
import { JOB_CATEGORY_REQUIRED_QUALIFICATION_WITH_VALIDATION_MAP } from './constants/required-qualification-with-validation';
import { removeDuplicated, flatten } from './util/array';

class RequiredQualificationByJobCategory {
  constructor(wrapper) {
    this.$wrapper = $(wrapper);
    this.$checkboxes = this.$wrapper.find('.js-required-qualification-by-job-category__checkbox');
    this.$errorMessage = this.$wrapper.find('.js-required-qualification-by-job-category__error-msg');
  }

  init = () => {
    this.update();
    this.$wrapper.on('change', '.js-required-qualification-by-job-category__checkbox', this.update);
  }

  update = () => {
    const text = this.isValid() ? '' : '応募条件を満たす資格/免許が選択されていません。お持ちの場合はチェックしてください。';

    this.$errorMessage.text(text);
  }

  createCompared = () => {
    const jobCategoryId = this.$checkboxes.eq(0).data('required-qualification-job-category-id');
    const targetJobCategoryRequiredQualificationMap = JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP[jobCategoryId];

    if (!targetJobCategoryRequiredQualificationMap) {
      return {
        requiredQualificationIds: [],
        checkedQualificationIds: [],
      };
    }

    const requiredQualificationIds = removeDuplicated(
      flatten(
        targetJobCategoryRequiredQualificationMap.map(v => Object.keys(v).sort()),
      ),
    );
    const checkedQualificationIds = removeDuplicated(
      [
        ...(this.$checkboxes.filter(':checked')),
      ].map(v => v.value).sort(),
    );

    return {
      requiredQualificationIds,
      checkedQualificationIds,
    };
  }

  isValid = () => {
    const { requiredQualificationIds, checkedQualificationIds } = this.createCompared();
    const filteredCheckedIds = checkedQualificationIds.filter(id => requiredQualificationIds.includes(id));

    if (requiredQualificationIds.length < 1) {
      return true;
    }

    return filteredCheckedIds.some(id => requiredQualificationIds.includes(id));
  }
}

class RequiredQualificationByJobCategoryWithValidation extends RequiredQualificationByJobCategory {
  constructor(wrapper) {
    super();
    this.$wrapper = $(wrapper);
    this.$checkboxLabels = this.$wrapper.find('.js-required-qualification-by-job-category-with-validation__checkbox-label');
    this.$checkboxes = this.$wrapper.find('.js-required-qualification-by-job-category-with-validation__checkbox');
    this.$errorMessage = this.$wrapper.find('.js-required-qualification-by-job-category-with-validation__error-msg');
    this.$submitButton = $('.js-form-manager__submit');
  }

  init = () => {
    this.$wrapper.on('change', '.js-required-qualification-by-job-category-with-validation__checkbox', this.update);
    this.$submitButton.on('click', this.update);
  }

  update = () => {
    const result = this.isValid();
    const text = result ? '' : '応募条件を満たす資格・免許が正しく選択されていません。すでにお持ち、あるいは取得予定の資格・免許がある場合はチェックしてください。';

    if (result) {
      this.$checkboxLabels.removeClass('u-is-error');
    } else {
      this.$checkboxLabels.addClass('u-is-error');
    }

    this.$errorMessage.text(text);
  }

  createCompared = () => {
    const jobCategoryId = this.$checkboxes.eq(0).data('required-qualification-job-category-id');
    const targetJobCategoryRequiredQualificationMap = JOB_CATEGORY_REQUIRED_QUALIFICATION_WITH_VALIDATION_MAP[jobCategoryId];

    if (!targetJobCategoryRequiredQualificationMap) {
      return {
        requiredQualificationIds: [],
        checkedQualificationIds: [],
      };
    }

    const requiredQualificationIds = removeDuplicated(
      flatten(
        targetJobCategoryRequiredQualificationMap.map(v => Object.keys(v).sort()),
      ),
    );
    const checkedQualificationIds = removeDuplicated(
      [
        ...(this.$checkboxes.filter(':checked')),
      ].map(v => v.value).sort(),
    );

    return {
      requiredQualificationIds,
      checkedQualificationIds,
    };
  }
}

export {
  RequiredQualificationByJobCategory,
  RequiredQualificationByJobCategoryWithValidation,
};
