import * as yup from 'yup';

export const memberRequiredAgentAvailableSchema = {
  'member[agent_available]': {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      const value = $form.find('[name="member[agent_available]"]:checked').val();
      if (value === undefined) {
        return -1;
      }

      return value;
    },
    getStyleTargetElement: ($form: JQuery<HTMLElement>) => {
      return $form.find('[name="member[agent_available]"]').closest('.js-form-manager__fieldset').find('label');
    },
    validation: yup
      .number()
      .min(1, '応募する場合は選択してください'), // .required()でundefinedが弾かれないため、.min(1, 'xx')でundefinedの時に渡される-1は弾き「利用する」を選択した時に渡される1を通す
    count: true,
  },
};
