import $ from 'jquery';

const POSTAL_CODE_VALID_LENGTH = 7;

class AddressFillFromPostalCode {
  constructor({ postalCode, prefecture, city, town, cityOptionGenerator, done }) {
    this.$postalCode = $(postalCode);
    this.$prefecture = $(prefecture);
    this.$city = $(city);
    this.$town = $(town);
    this.cityOptionGenerator = cityOptionGenerator;
    this.done = done;
  }

  init = () => {
    if (this.$postalCode.length < 1) {
      return;
    }
    this.$postalCode.on('keyup blur', this.fill);
    this.cityOptionGenerator.init();
  }

  fill = async () => {
    const postalCode = this.$postalCode.val();

    if (postalCode.length !== POSTAL_CODE_VALID_LENGTH) {
      return;
    }

    const response = await this.getTowns(postalCode);
    const town = response.towns[0];

    if (!town) {
      return;
    }
    await this.$prefecture.val(town.prefecture_id);
    await this.cityOptionGenerator.generate(town.prefecture_id);
    await this.$city.val(town.city_id);
    await this.$town.val(town.name);

    if (typeof this.done === 'function') {
      this.done({
        $prefecture: this.$prefecture,
        $city: this.$city,
      });
    }
  }

  getTowns = async (postalCode) => {
    const response = await $.ajax({
      type: 'GET',
      url: '/api/towns',
      data: {
        postal_code: postalCode,
      },
    });

    return response;
  }
}

export {
  AddressFillFromPostalCode,
};
