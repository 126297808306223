import $ from 'jquery';

class InputtedTextCounter {
  constructor(el) {
    this.$counter = $(el);
    this.$textarea = $(`#${this.$counter.data('inputted-text-counter-id')}`);
  }

  init = () => {
    this.$textarea.on('keyup', () => {
      this.$counter.text(this.$textarea.val().length);
    });
  }
}

export {
  InputtedTextCounter,
};
