import $ from 'jquery';

class FeatureReadMore {
  $wrapper: JQuery<HTMLElement>;
  $body: JQuery<HTMLElement>;
  $opener: JQuery<HTMLElement>;
  $closer: JQuery<HTMLElement>;
  hiddenClassName: string;
  fullDisplayClassName: string;

  constructor(el: HTMLElement) {
    this.$wrapper = $(el);
    this.$body = this.$wrapper.find('.js-feature-read-more__body');
    this.$opener = this.$wrapper.find('.js-feature-read-more__opener');
    this.$closer = this.$wrapper.find('.js-feature-read-more__closer');
    this.hiddenClassName = 'u-hidden';
    this.fullDisplayClassName = this.$body.data('feature-read-more-visible-class-name') || 'u-height-auto u-max-height-initial';
  }

  init = () => {
    this.$opener.on('click', (e) => {
      e.preventDefault();
      this.show();
    });
    this.$closer.on('click', (e) => {
      e.preventDefault();
      this.hide();
    });
  }

  show = () => {
    this.$body.addClass(this.fullDisplayClassName);
    this.$opener.addClass(this.hiddenClassName);
    this.$closer.removeClass(this.hiddenClassName);
    this.updateHiddenClass(false);
  }

  hide = () => {
    this.$body.removeClass(this.fullDisplayClassName);
    this.$opener.removeClass(this.hiddenClassName);
    this.$closer.addClass(this.hiddenClassName);
    this.updateHiddenClass(true);
  }

  updateHiddenClass = (isToHide: boolean) => {
    const method = isToHide ? 'addClass' : 'removeClass';
    this.$body.each((_, current) => {
      const $current = $(current);
      const hiddenClassName = $(current).data('feature-read-more-body-hidden-class-name');
      if (hiddenClassName) {
        $current[method](hiddenClassName);
      }
    });
  }
}

export {
  FeatureReadMore,
};
